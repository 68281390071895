import React, { useEffect, useState } from 'react';
import TvMarquee from '../components/TvMarquee';
import { supabase } from '../supabase';
import TvActivityNavbar from '../components/TvActivityNavbar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const TvScreen = ({ data }) => {
  const [activeSlide, setActiveSlide] = useState(2);
  const [activityData, setActivityData] = useState([]);
  useEffect(() => {
    getStudentActivity();
  }, []);

  async function getStudentActivity() {
    await supabase.auth.getUser().then((value) => {
      if (value.data?.user) {
        sessionStorage.setItem("user_id", value.data.user.id);
      }
    });

    const user_id = sessionStorage.getItem("user_id");
    console.log(user_id);

    let { data: School, error1 } = await supabase
      .from("Member")
      .select("school_id")
      .eq("user_id", user_id)
      .order("lastlogin_date", { ascending: false });

    window.schoolId = School[0].school_id;

    // Array For Filtering:
    const houseActivity = [];
    const FilteredHouseActivity = [];
    console.log(houseActivity);

    // GETTING HOUSE ACTIVITY:
    let { data: StudentActivity, error } = await supabase
      .from('StudentActivity')
      .select('*')
      .order('created_date', { ascending: false })

    console.log("BEFORE MAPPING:", StudentActivity)

    Window.description = StudentActivity;
    StudentActivity.forEach((obj) => {
      const object = {
        "studentactivity_id": obj.studentactivity_id,
        "house_id": obj.house_id,
        "media_link": obj.media_link,
        "house_name": obj.house_name,
        "house_crest": obj.house_crest,
        "description": obj.description
      }
      houseActivity.push(object);
    });

    // GETTING MEDIA LINK:
    let { data: StudentActivityMedia, erro } = await supabase
      .from('StudentActivityMedia')
      .select('*')

    StudentActivityMedia.map((item) => {
      const data = {
        "studentactivity_id": item.studentactivity_id,
        "house_id": item.house_id,
        "media_link": item.media_link,
        "house_name": item.house_name,
        "house_crest": item.house_crest,
        "description": item.description
      }
      houseActivity.push(data);
    });
    // SELECTING THE NEWS COUNT AND NEWS DURATION:
    let { data: NewsCount, errors } = await supabase
      .from('School')
      .select('news_count')
      .eq("school_id", window.schoolId)
    window.newsCount = NewsCount[0].news_count
    console.log(window.newsCount)

    let { data: NewsDuration, er } = await supabase
      .from('School')
      .select('news_duration')
      .eq("school_id", window.schoolId)
    window.newsDuration = NewsDuration[0].news_duration
    console.log(window.newsDuration)

    // Iterate over the HouseActivity array
    for (let i = 0; i < houseActivity.length; i++) {
      const firstObj = houseActivity[i];

      // Find the corresponding object in the houseActivity array
      const secondObj = houseActivity.find(obj => obj.studentactivity_id === firstObj.studentactivity_id);

      // If House array is having house_id, add house array to filtered house array
      if (secondObj) {
        firstObj.house_name = secondObj.house_name;
        firstObj.house_crest = secondObj.house_crest;
        firstObj.studentactivity_id = secondObj.studentactivity_id;
        firstObj.house_id = secondObj.house_id;
        firstObj.description = secondObj.description;
      }
      if (firstObj && firstObj.media_link !== undefined) {
        FilteredHouseActivity.push(firstObj);
      }
    }
    console.log(FilteredHouseActivity);

    //GETTING ALL HOUSES:
    const { data: House, err } = await supabase
      .from("House")
      .select("*")
      .eq("active", "TRUE");
    Window.tvHouse = House;
    console.log(Window.tvHouse);

    let FinalFilteredHouse = [];

    // Iterate over the FilteredHouseActivity array
    for (let i = 0; i < FilteredHouseActivity.length; i++) {
      const firstObj = FilteredHouseActivity[i];

      // Find the corresponding object in the House array
      const secondObj = House.find(obj => obj.house_id === firstObj.house_id);

      // If House array is having house_id, add house array to filtered house array
      if (secondObj) {
        firstObj.house_name = secondObj.house_name;
        firstObj.house_crest = secondObj.house_crest;
        firstObj.house_id = secondObj.house_id;
      }
      FinalFilteredHouse.push(firstObj);
      const activityArray = FinalFilteredHouse.slice(0, window.newsCount);
      setActivityData(activityArray);
    }
    console.log(activityData);
    window.arr = FinalFilteredHouse;

    window.array = FinalFilteredHouse.map((element) => {
      return (
        <div className="card"><img className=" tvcard-imgimg" src={element.media_link} />
          <div className="house-detail-tv">
            <span className="d-flex house-data">
              <img className="house-img-tv" src={element.house_crest} />
              <span className="house-name-tv">{element.house_name}</span>
            </span>
            <p className="about-house-tv">{element.description}</p>
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <div className='tv-dashboard '>
        <div className='navbar-container'>
          <TvActivityNavbar />
        </div>
        <div className='tv-container'>
          <div className='tv-swiper-container'>
            <OwlCarousel items={3} autoplay={true} center={true} dots={false} loop={true} autoplayTimeout={window.newsDuration} showThumbs={false}
              className="owl-theme"
              nav
            >
              {activityData.map(element => (
                <div className="card"><img className=" tvcard-imgimg" src={element.media_link} />
                  <div className="house-detail-tv">
                    <span className="d-flex house-data">
                      <img className="house-img-tv" src={element.house_crest} />
                      <span className="house-name-tv">{element.house_name}</span>
                    </span>
                    <p className="about-house-tv">{element.description}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        <TvMarquee data={data} />
      </div>
    </>
  );
};

export default TvScreen;