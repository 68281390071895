import React from 'react'

const TvHouseCard = (house) => {
  return (
    <div className='house-tv-card'>
      <div className='house-tv-logo-and-name'>
        <div className='house-tv-logo'>
          <img className='house-tv-logo' src={house.houseImage} />
        </div>
        <div className='house-tv-name'>{house.houseName}</div>
      </div>
      <div className={house.indexValue === 0 ? 'house-tv-points' : 'house-tv-silver'}>
        <div >{house.housePoints} Points</div>
      </div>
    </div>
  )
}

export default TvHouseCard


