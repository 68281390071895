import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";

const Dropzone = () => {
  const [selectedImages, setSelectedImages] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true, // Allow multiple images to be added
  });

  return (
    <div>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the image(s) here...</p>
        ) : (
          <p>Drag and drop image(s) here, or click to select</p>
        )}
      </div>
      <div>
        {selectedImages.map((imageSrc, index) => (
          <img
            key={index}
            src={imageSrc}
            alt={`Selected ${index}`}
            style={{
              width: "100%",
              marginTop: "20px",
              maxWidth: "200px",
              maxHeight: "200px",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Dropzone;
