
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { supabase } from '../supabase';
import TvActivityNavbar from '../components/TvActivityNavbar';
import TvMarquee from '../components/TvMarquee';
import { useNavigate } from 'react-router-dom';
import PageLoader from "../components/PageLoader";

const CarouselCards = ({ data }) => {
    const navigate = useNavigate();
    const [activityData, setActivityData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [tvHouse, setTvHouse] = useState([]);

    useEffect(() => {
        setPageLoading(true)
        const url = window.location.href;
        const params = new URLSearchParams(new URL(url).search);
        window.schoolId = params.get("school");
        window.mode = params.get("mode");
        console.log(window.schoolId, window.mode)
        tvHouseData();
        getStudentActivity();
        setPageLoading(false)
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const schoolValue = window.schoolId;
            const modeValue = window.mode;
            const url = `/viewer?school=${schoolValue}&mode=${modeValue}`;
            navigate(url);
        }, 30000); // 30000 milliseconds = 30 seconds

        return () => clearTimeout(timeoutId); // Cleanup the timeout when the component unmounts
    }, [navigate]);

    useEffect(() => {
        console.log(activityData);
        if (activityData.length > 0) {
            window.arr1 = activityData.map((element) => {
                return (
                    <div className="card">
                        <div>
                            {element.media_link.length === 0 ? (
                                // If element.media_link is empty, display a default image
                                <img
                                    className="tvcard-imgimg"
                                    src="path/to/default_image.jpg"
                                    alt="Default Image"
                                />
                            ) : (
                                // Otherwise, display the first image from element.media_link array
                                <img
                                    className="tvcard-imgimg"
                                    src={element.media_link[0].media_link}
                                    alt="Media Image"
                                />
                            )}
                        </div>
                        {/* <img className=" tvcard-imgimg" src={element.media_link[0].media_link} /> */}
                        <div className="house-detail-tv">
                            <span className="d-flex house-data">
                                <img className="house-img-tv" src={element.house_crest} />
                                <span className="house-name-tv">{element.house_name}</span>
                            </span>
                            <p className="about-house-tv">{element.description}</p>
                        </div>
                    </div>
                );
            });
            setPageLoading(false)
        } else {
            console.log("no data")
        }
    }, [activityData]);

    async function tvHouseData() {
        const { data: House, error } = await supabase
            .from("House")
            .select("*")
            .eq("school_id", window.schoolId)
            .eq("active", "TRUE");
        console.log(House)
        setTvHouse(House);
        setPageLoading(false)
    }
    
    const sortedData = [...tvHouse].sort((a, b) => b.total_points - a.total_points);
    console.log(sortedData)

    async function getStudentActivity() {
        setPageLoading(true
        )
        // SELECTING THE NEWS COUNT AND NEWS DURATION:
        let { data: NewsCount, errors } = await supabase
            .from('School')
            .select('news_count')
            .eq("school_id", window.schoolId)
        window.newsCount = NewsCount[0].news_count
        console.log(window.newsCount)

        let { data: NewsDuration, er } = await supabase
            .from('School')
            .select('news_duration')
            .eq("school_id", window.schoolId)
        window.newsDuration = NewsDuration[0].news_duration
        console.log(window.newsDuration)

        const sliderArray = [];
        const { data: slider } = await supabase.from('StudentActivity').select(`
        studentactivity_id, 
        description, 
        house_id,
        StudentActivityMedia ( studentactivity_id, media_link )
        `).eq("school_id", window.schoolId)
            .order("created_date", { ascending: false });
        console.log(slider);

        const { data: Houses } = await supabase
            .from("House")
            .select("*")
            .eq("school_id", window.schoolId)
            .eq("active", "TRUE");
        console.log(Houses)

        const houseIds = Houses.map(house => house.house_id);

        slider.forEach((obj) => {
            const object = {
                "studentactivity_id": obj.studentactivity_id,
                "house_id": obj.house_id,
                "media_link": obj.StudentActivityMedia,
                "house_name": obj.house_name,
                "house_crest": obj.house_crest,
                "description": obj.description
            }
            sliderArray.push(object);
        });
        console.log(sliderArray)
        const mappedArray = sliderArray.map(sliderItem => {
            const house = Houses.find(houseItem => houseItem.house_id === sliderItem.house_id);
            if (house) {
                const { house_crest, house_name } = house;
                return { ...sliderItem, house_crest, house_name };
            }
            return sliderItem;
        });

        console.log(mappedArray);
        const activityArray = mappedArray.slice(0, 5);
        console.log(activityArray)

        const mappedData1 = activityArray.flatMap((item) =>
            item.media_link.map((media) => ({
                ...item,
                media_link: [media],
            }))
        );

        console.log(mappedData1);
        setActivityData(mappedData1);
        console.log(activityData)
        console.log(activityData)
        setPageLoading(false)
    }

    return (
        <>
            {pageLoading ? <PageLoader /> : ""
            }
            <div>
                <div className='tv-dashboard '>
                    <div className='navbar-container'>
                        <TvActivityNavbar />
                    </div>
                    <div className='tv-container'>
                        <div className='tv-swiper-container'>
                            <div className="row">
                                <OwlCarousel items={3} autoplay={true} center={true} dots={false} loop={true} autoplayTimeout={window.newsDuration}
                                    className="owl-theme"
                                    nav
                                >
                                    {window.arr1}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <TvMarquee data={sortedData} />
                </div>
            </div>
        </>
    )
}

export default CarouselCards