import React, { useEffect, useState } from "react";
import TvHouseCard from "../components/TvHouseCard";
import { supabase } from "../supabase";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TvCards = ({ data }) => {
    const [tvHouse, setTvHouse] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const currentPage = "tvdashboard";
    const date = new Date();
    const [currentTime, setCurrentTime] = useState('00:00:00 AM');
    const [SchoolName, setSchoolName] = useState('');
    
    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(new URL(url).search);
        window.schoolId = params.get("school");
        const mode = params.get("mode");
        console.log(window.schoolId, mode)
        if (SchoolName == '') {
            schoolName();
        }
        else { }

        const timer = setTimeout(() => {
            const options = {
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
                timeZone: 'Asia/Kolkata'
            };
            const formattedTime = new Date().toLocaleString('en-US', options);
            setCurrentTime(formattedTime);
        }, 1000);
    }, [currentTime]);

    async function schoolName() {

        let { data: School } = await supabase
            .from("School")
            .select("school_name")
            .eq("school_id", window.schoolId);
        setSchoolName(School[0].school_name);
    }

    return (
        <>
            <div className="navbar-container">
                <div className='tv-navbar '>
                    <div className='tv-school-name '>
                        <h6>{SchoolName}</h6>
                        {/* <h6>INDIAN SCHOOL, DUBAI</h6> */}
                    </div>
                    <div className='tv-housepoints'>
                        HOUSE POINTS
                    </div>
                    <div className='tv-time-date'>
                        <div className='tv-time-logo'></div>
                        <div className='tv-date-info'>{currentTime} | {date.toLocaleDateString()}</div>
                    </div>
                </div>
            </div>
            <div>
                <div className="tv-dashboard">
                    <div className="tv-container">
                        <div className="tv-cards-container">
                            {data.map((house, index) => (
                                <div key={house.house_id} className="individual-tv-card">
                                    <TvHouseCard
                                        houseId={house.house_id}
                                        houseName={house.house_name}
                                        houseImage={house.house_crest}
                                        housePoints={house.total_points}
                                        indexValue={index}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TvCards;