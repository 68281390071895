import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import envelopeicon from "../assets/images/auth/envelope-icon.svg";
import lockicon from "../assets/images/auth/lock-icon.svg";
import googleicon from "../assets/images/auth/google-icon.svg";
import booksicon from "../assets/images/auth/books-icon.svg";
import eye from "../assets/images/auth/eye.svg";
import eyeslash from "../assets/images/auth/eyeslash.svg";
import logo from "../assets/images/auth/logo.svg";
import { supabase } from "../supabase";
import PageLoader from "./PageLoader";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

function Signin() {
  //page redirect function
  let navigate = useNavigate();
  const MovetoForgotpassword = () => {
    let path = `/forgotpassword`;
    navigate(path);
  };

  const [user, setUser] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [auth, setAuth] = useState(false);
  const [isMob, setisMob] = useState(false);

  useEffect(() => {
    let platform = Capacitor.isNativePlatform;

    if (platform == "() => getPlatform() !== 'web'") {
      setisMob(false);
    } else {
      setisMob(true);
    }
    //getting acess and the refresh tokens:
    async function fetchData() {
      // You can await here
      const acessToken = localStorage.getItem("accesstoken");
      const refreshToken = localStorage.getItem("refreshtoken");
      console.log("inside useeffect session check");
      if (acessToken != null && refreshToken != null) {
        const { data, error } = await supabase.auth.getSession();
        console.log(data, error);
        if (data.session == null) {
          navigate("/");
        } else {
          navigate("dashboard");
        }
        // Redirect to the dashboard
      }
    }
    fetchData();
  }, []);

  //Login with OAuth
  const [loading, setLoading] = useState(false);
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (Capacitor.getPlatform() === "web") {
      let { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        persistSession: false,
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "select_account",
          },
          redirectTo: "https://hps.addant.com/dashboard",
        },
      });
    } else {
      let { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        persistSession: false,
        options: {
          queryParams: {
            access_type: "offline",
            prompt: "select_account",
          },
          redirectTo: isMob
            ? "hps://hps.addant.com/dashboard"
            : "https://hps.addant.com/dashboard",
        },
      });
    }

    setLoading(false);
  };

  //Login with email & password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleLoginEmail = async (event) => {
    setPageLoading(true);
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("school_id");
    event.preventDefault();
    const userName = email.trim();
    let { data, error } = await supabase.auth.signInWithPassword({
      email: userName,
      password: password,
    });
    localStorage.setItem("accesstoken", data.access_token);
    localStorage.setItem("refreshtoken", data.refresh_token);
    setPageLoading(false);
    if (error) {
      setMessage(`Invalid credentials`);
      setTimeout(() => {
        setMessage("");
      }, 1000);

      setPassword("");
    } else {
      let { data: Member, error } = await supabase
        .from("Member")
        .select("school_id")
        .eq("user_id", data.user.id)
        .order("lastlogin_date", { ascending: false });

      let { data: RoleId, err } = await supabase
        .from("Member")
        .select("role_id")
        .eq("user_id", data.user.id);

      if (RoleId.length == 0) {
        setMessage(`Role is not assigned. Contact Admin!`);
        setTimeout(() => {
          setMessage("");
        }, 3000);

        setPassword("");
      }
      window.roleId = RoleId[0].role_id;
      sessionStorage.setItem("user_id", data.user.id);
      sessionStorage.setItem("school_id", Member[0].school_id);
      if (error) {
        console.log(error);
        return [];
      } else {
        await supabase
          .from("Member")
          .update({ lastlogin_date: new Date() })
          .eq("user_id", data.user.id)
          .eq("school_id", Member[0].school_id)
          .then((response) => {
            console.log("Update successful:", response);
          });
        if (isMob) {
          window.location.href = "/dashboard";
        } else {
          navigate("dashboard");
        }
        return data;
      }
    }
  };

  //Register the appUrlOpen listener for forgeot password & google sigin deeplinks
  useEffect(() => {
    window.handleAppUrlOpen = async (event) => {
      const url = event.url;
      const refresh_token = url.split("&refresh_token=").pop().split("&")[0];
      const { data, error } = await supabase.auth.refreshSession({
        refresh_token,
      });
      const { session, user } = data;
      setUser(data.user);
      setAuth(true);
      if (url.startsWith("hps://hps.addant.com/dashboard")) {
        window.location.href = "/dashboard";
      }
      if (url.startsWith("hps://hps.addant.com/reset-password")) {
        window.location.href = "/reset-password";
      }
    };
    App.addListener("appUrlOpen", window.handleAppUrlOpen);
    return () => {
      App.removeListener("appUrlOpen", window.handleAppUrlOpen);
    };
  }, []);

  //password toggle
  const [showPassword, setShowPassword] = useState(false);
  const [imageSource, setImageSource] = useState(eye);
  const handleClick = () => {
    if (imageSource === eye) {
      setImageSource(eyeslash);
      setShowPassword(!showPassword);
    } else {
      setImageSource(eye);
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="login">
      {pageLoading ? <PageLoader /> : ""}
      <main className="login__main">
        {/* <!-- ====== Logo ====== --> */}
        <div className="login__brand">
          <div className="login__brand-logo">
            <img src={logo} alt="Logo" />
          </div>
          <h3 className="login__brand-title">House Point System</h3>
          <h5 className="login__brand-subtitle">Teacher Login</h5>
        </div>

        {/* <!-- ====== Content ====== --> */}
        <div className="login__main-content">
          <div className="login__card">
            <img src={booksicon} className="login__card-img" alt="image" />
            <form className="login__form">
              <div className="login__form-row">
                <img
                  src={envelopeicon}
                  className="login__form-icon"
                  alt="icon"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  id="email"
                  placeholder="email address"
                />
              </div>

              <div className="login__form-row">
                <img src={lockicon} className="login__form-icon" alt="icon" />
                <img
                  src={imageSource}
                  className="login__form-eye fa fa-eye"
                  alt="icon"
                  onClick={handleClick}
                />
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="password"
                />
              </div>

              <div className="login__form-text">
                <span>{message && <p className="danger">{message}</p>}</span>
              </div>

              <div className="login__form-links">
                <a onClick={MovetoForgotpassword} className="login__form-link">
                  Forgot Password
                </a>
                <button
                  type="submit"
                  className="btn btn-primary login__form-btn"
                  onClick={handleLoginEmail}
                >
                  Login
                </button>
              </div>
            </form>

            <div className="login__card-links">
              <button
                type="button"
                className="login__card-btn"
                onClick={handleLogin}
                disabled={loading}
              >
                <img src={googleicon} alt="Google logo" className="icon" />
                <span>Sign in with Google</span>
              </button>
              <a href="#" className="login__card-link">
                Terms & Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* <!-- ============= Background Images Start ========== --> */}
      <span className="login-top-img sm-none">
        <svg
          width="411"
          height="468"
          viewBox="0 0 411 468"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-192 364.5L-59.2411 231.741C5.20951 296.192 109.872 296.071 174.471 231.472C239.07 166.873 239.191 62.2106 174.74 -2.24004L307.499 -134.999C445.027 2.52968 444.77 226.08 306.925 363.926C169.079 501.771 -54.4715 502.029 -192 364.5Z"
            fill="currentColor"
          />
        </svg>
      </span>

      <span className="login-right-img">
        <svg
          width="262"
          height="525"
          viewBox="0 0 262 525"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M262 0V139.537C194.232 139.537 139.271 194.603 139.271 262.5C139.271 330.397 194.232 385.463 262 385.463V525C117.393 525 0 407.383 0 262.5C0 117.617 117.393 0 262 0Z"
            fill="currentColor"
          />
        </svg>
      </span>

      <span className="login-bottom-img">
        <svg
          width="481"
          height="199"
          viewBox="0 0 481 199"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M481 199L353.158 199C353.158 147.527 302.707 105.782 240.5 105.782C178.293 105.782 127.842 147.527 127.842 199L0 199C0 89.165 107.76 0 240.5 0C373.24 0 481 89.165 481 199Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {/* <!-- ============= Background Images End ============ --> */}

      {/* <!-- ============= Footer ============ --> */}
      <footer className="login__footer">
        <div className="login__footer-container">
          <div className="login__footer-row">
            <p>&copy; 2023 House Points System</p>
            <a href="#" className="login__footer-link">
              Contact Us
            </a>
          </div>

          <div className="login__footer-row">
            <a href="#" className="login__footer-link">
              Terms & Conditions
            </a>
            <a href="#" className="login__footer-link">
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Signin;
