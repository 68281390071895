import React, { useEffect, useState } from 'react';
import activityIcon from '../assets/images/activity-gallery/activity-icon.png';

const TvMarquee = ({ data }) => {
  return (
    <div className='tv-marquee'>
      <div className='tv-marquee-logo'>
        <img className='tv-activity-icon' src={activityIcon} />
      </div>

      <marquee className="marquee">
        <div className='tv-marquee-scroll-space'>
          {data.map((house) => {
            return (
              <div className='tv-marquee-runner marquee'>
                <div className='tv-marquee-img'>
                  <div className='tv-marquee-house-ind-logo'>
                    <img className='tv-marquee-img-house' src={house.house_crest} alt='house' />
                  </div>
                </div>
                <div className='tv-marquee-house'>
                  <div className='tv-marquee-points'>
                    {house.house_name} - {house.total_points} POINTS
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </marquee>
    </div>
  )
}

export default TvMarquee