import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import logo from "../assets/images/dashboard/logo.svg";
import crown from "../assets/images/dashboard/crown.svg";
import logouticon from "../assets/images/dashboard/logout-icon.svg";
import dotsicon from "../assets/images/dashboard/dots-icon.svg";
import check from "../assets/images/dashboard/check.svg";
import checkbox from "../assets/images/dashboard/checkbox-icon.svg";
import houseiconfill from "../assets/images/dashboard/houseiconfill.svg";
import dark from "../assets/images/dashboard/dark.svg";
import light from "../assets/images/dashboard/light.svg";
import houseicon from "../assets/images/dashboard/house.svg";
import reset from "../assets/images/dashboard/reset.svg";
import ognisko from "../assets/images/dashboard/ognisko.png";
import { supabase } from "../supabase";
import PageLoader from "./PageLoader";
import DatePicker from "react-mobile-datepicker";
import Picker from "react-scrollable-picker";

function Dashboard() {
  //BetterScrolling:
  const [range, setRange] = React.useState({ title: "" });
  const [subRange, setSubRange] = React.useState({ title: "" });
  const [bScrollOptions, setBScrollOptions] = useState();
  const [bScrollMinusOptions, setBScrollMinusOptions] = useState();
  const [resettingHouseOptions, setResettingHouseOptions] = useState();
  const [resettingHouseRange, setResettingHouseRange] = React.useState({
    title: "",
  });
  const [activeItem, setActiveItem] = useState(0);

  //for add points better scroll:
  const handleBetterScrollChange = (name, value) => {
    HouseClick(value);
    setRange({
      ...range,
      [name]: value,
    });

    // Set the active state of the addpoints__amount-item
    const addpointsItems = document.querySelectorAll(".addpoints__amount-item");
    addpointsItems.forEach((item, index) => {
      item.classList.toggle("active", index === 0);
    });
  };
  //for sub points better scroll:
  const handleBetterScrollMinusChange = (name, value) => {
    HouseClicksub(value);
    setSubRange({
      ...range,
      [name]: value,
    });

    const addpointsItems = document.querySelectorAll(".addpoints__amount-item");
    addpointsItems.forEach((item, index) => {
      item.classList.toggle("active", index === 0);
    });
  };

  const handleResettingHouseChange = (name, value) => {
    getResetSchoolId(value);
    setResettingHouseRange({
      ...range,
      [name]: value,
    });
  };

  // resetting checkboxes in housepoint:
  const resetCheckboxes = () => {
    const checkboxes = document.querySelectorAll(
      '.multiselect__slider input[type="checkbox"]:not(#subtract-all-house)'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const checkboxesadd = document.querySelectorAll(
      '.multiselect__slider input[type="checkbox"]:not(#add-all-house)'
    );
    checkboxesadd.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  //tabs toogle in modal
  const [isActive, setIsActive] = useState(true);
  const toggleActiveClass = () => {
    if (!isActive) {
      // Only execute the following code if the "Add" tab is not already active
      updatepoints(100);
      houseCriteria();
      setIsActive(!isActive);
      updateMyArray([]);
      resetCheckboxes();
      setRange({ title: "" });
      //setSubRange({ title: "" });
    }
  };
  const MultitoggleActiveClass = () => {
    updatepoints(100);
    houseCriteria();
    setIsActive(1);
    updateMyArray([]);
    resetCheckboxes();

    const allAddPointItems = document.querySelectorAll(
      ".multi-add-points-tab .addpoints__amount-item"
    );
    allAddPointItems.forEach((item) => {
      item.classList.remove("addactive");
    });

    const firstAddPointItem = document.querySelector(
      ".multi-add-points-tab .addpoints__amount-item:first-child"
    );
    firstAddPointItem.classList.add("addactive");
  };
  const MultitoggleActiveClass2 = () => {
    updatepoints(-100);
    houseCriteriaSub();
    setIsActive(0);
    updateMyArray([]);
    resetCheckboxes();

    const allSubtractPointItems = document.querySelectorAll(
      ".multi-subtract-points-tab .addpoints__amount-item"
    );
    allSubtractPointItems.forEach((item) => {
      item.classList.remove("subactive");
    });

    const firstSubtractPointItem = document.querySelector(
      ".multi-subtract-points-tab .addpoints__amount-item:first-child"
    );
    firstSubtractPointItem.classList.add("subactive");
  };
  const toggleActiveClass2 = () => {
    if (isActive) {
      updatepoints(-100);
      houseCriteriaSub();
      setIsActive(!isActive);
      updateMyArray([]);
      resetCheckboxes();
      setSubRange({ title: "" });
    }
  };

  //modals toggle
  const handleCardClick = (cardId) => {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("refreshtoken");
    setPageLoading(true);
    setRange({ title: "" });
    setSubRange({ title: "" });
    dropdownRef.current.classList.remove("show");
    dropdownOverlayRef.current.classList.remove("show");
    document.querySelector(`.${cardId}`).classList.add("show");
    resetCheckboxes();
    setPageLoading(false);
    document.body.style.overflow = "hidden";
  };

  const handleCardCloseClick = (cardId) => {
    document.querySelector(`.${cardId}`).classList.remove("show");
    document.body.style.overflow = "auto";
  };

  //darkmode
  const circleToggle = document.querySelector("#dark-light-mode");

  // Enabling light mode
  const enableLightMode = async () => {
    document.body.classList.add("lightmode");
    const school_id = sessionStorage.getItem("school_id");
    const user_id = sessionStorage.getItem("user_id");

    try {
      const { data, error } = await supabase
        .from("Member")
        .update({ lastscreenmode: "dark" })
        .eq("user_id", user_id)
        .eq("school_id", school_id);

      if (error) {
        console.error("Failed to update lastscreenmode:", error);
        return;
      }

      console.log("Update successful");
      localStorage.setItem("lastscreenmode", "dark");
    } catch (error) {
      console.error("Failed to update lastscreenmode:", error);
    }
  };

  // Disabling light mode
  const disableLightMode = async () => {
    document.body.classList.remove("lightmode");
    const school_id = sessionStorage.getItem("school_id");
    const user_id = sessionStorage.getItem("user_id");

    try {
      const { data, error } = await supabase
        .from("Member")
        .update({ lastscreenmode: "light" })
        .eq("user_id", user_id)
        .eq("school_id", school_id);

      if (error) {
        console.error("Failed to update lastscreenmode:", error);
        return;
      }

      console.log("Update successful");
    } catch (error) {
      console.error("Failed to update lastscreenmode:", error);
    }
  };

  // Toggle the screen mode
  const onClickScreenMode = async (event) => {
    dropdownRef.current.classList.remove("show");
    dropdownOverlayRef.current.classList.remove("show");
    const school_id = sessionStorage.getItem("school_id");
    const user_id = sessionStorage.getItem("user_id");

    try {
      const { data: memberData, error } = await supabase
        .from("Member")
        .select("lastscreenmode")
        .eq("user_id", user_id)
        .eq("school_id", school_id);

      if (error) {
        console.error("Failed to fetch member data:", error);
        return;
      }

      const member = memberData[0];
      const currentScreenMode = member.lastscreenmode;

      if (currentScreenMode !== "light") {
        disableLightMode();
        if (circleToggle) {
          circleToggle.classList.add("circle-move");
          document.querySelector("#dark-light-mode span").textContent =
            "Switch to Dark Mode";
          document.querySelector("#dark-light-mode img").src = dark;
        }
      } else {
        enableLightMode();
        if (circleToggle) {
          circleToggle.classList.remove("circle-move");

          document.querySelector("#dark-light-mode span").textContent =
            "Switch to Light Mode";
          document.querySelector("#dark-light-mode img").src = light;
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Fetching last screen mode and reflecting the theme
  async function lastscreenmodefetch() {
    const school_id = sessionStorage.getItem("school_id");
    const user_id = sessionStorage.getItem("user_id");

    try {
      const { data: memberData, error } = await supabase
        .from("Member")
        .select("lastscreenmode")
        .eq("user_id", user_id)
        .eq("school_id", school_id);

      if (error) {
        console.error("Failed to fetch member data:", error);
        return;
      }

      const member = memberData[0];
      const currentScreenMode = member.lastscreenmode;

      if (currentScreenMode !== "light") {
        document.body.classList.add("lightmode");
        window.mode = "Switch to Light Mode";
        document.querySelector("#dark-light-mode span").textContent =
          "Switch to light Mode";
        document.querySelector("#dark-light-mode img").src = light;
        if (circleToggle) {
          circleToggle.classList.add("circle-move");
        }
      } else {
        document.body.classList.remove("lightmode");
        window.mode = "Switch to dark Mode";
        document.querySelector("#dark-light-mode span").textContent =
          "Switch to Dark Mode";
        document.querySelector("#dark-light-mode img").src = dark;

        if (circleToggle) {
          circleToggle.classList.remove("circle-move");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    schoolSelectionfetch();
  }

  //dropdown
  const dropdownRef = useRef(null);
  const dropdownOverlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.className !== "search__dropwdown-imgs" &&
        event.target.className !== "search__dropwdown-btn"
      ) {
        dropdownRef.current.classList.remove("show");
        dropdownOverlayRef.current.classList.remove("show");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    dropdownRef.current.classList.toggle("show");
    dropdownOverlayRef.current.classList.toggle("show");
  };

  //reset house
  const getResetSchoolId = (resetHouseId, resethouseName) => {
    window.resetHouseId = resetHouseId;
    window.resetname = resethouseName;
    const RestButtons = document.getElementsByClassName(
      "reset__slider-item item-reset"
    );
    for (let i = 0; i < RestButtons.length; i++) {
      window.button = RestButtons[i];
      const resetUI = window.button.querySelector("p").textContent;
      if (window.resetname === resetUI) {
        window.button.classList.add("active");
      } else {
        window.button.classList.remove("active");
   
        window.resetSliderItems = document.getElementsByClassName(
          "reset__slider-item all-house"
        );
        window.resetSliderItems[0].classList.remove("active");
      }
    }
  };

  const ResetAllHouses = (resetallhouses) => {
    window.resetHouseId = resetallhouses;

    window.resetSliderItems = document.getElementsByClassName(
      "reset__slider-item all-house"
    );
   
    for (let i = 0; i < window.resetSliderItems.length; i++) {
      const resetUI = window.resetSliderItems[0].querySelector("p").textContent;

      if (window.resetHouseId === resetUI) {
        window.resetSliderItems[i].classList.add("active");
  
        const RestButtons = document.getElementsByClassName(
          "reset__slider-item item-reset"
        );
        for (let i = 0; i < RestButtons.length; i++) {
          window.button = RestButtons[i];
          const resetUI = window.button.querySelector("p").textContent;
          window.button.classList.remove("active");
        }
      } else {
        window.resetSliderItems[i].classList.remove("active");
     
      }
    }
  };

  async function ResetHouses() {
    if (window.resetHouseId == "All Houses") {
      try {
        setPageLoading(true);
        const school_id = sessionStorage.getItem("school_id");
        const { data: houses, error: housesError } = await supabase
          .from("House")
          .select("house_id")
          .eq("active", "TRUE")
          .eq("school_id", school_id);
        if (housesError) {
          console.error(housesError);
          return;
        }

        // Update rows in the point table
        for (const house of houses) {
          const { data: points, error: pointsError } = await supabase
            .from("Point")
            .update({ reset_date: new Date() })
            .eq("house_id", house.house_id)
            .is("reset_date", null);

          if (pointsError) {
            console.error(pointsError);
            return;
          }
        }
      } catch (error) {
        console.error(error);
      }
      const school_id = sessionStorage.getItem("school_id");

      const { data, error } = await supabase
        .from("House")
        .update({ total_points: 0 })
        .eq("active", "TRUE")
        .eq("school_id", school_id);
      setPageLoading(true);

      if (error) {
        console.error("Error updating data:", error);
      } else {
        setPageLoading(false);
        console.log("Data updated successfully");
      }

      handleCardCloseClick("reset-houses-modal");
    } else {
      try {
        setPageLoading(true);
        const school_id = sessionStorage.getItem("school_id");

        // Update rows in the point table
        const { data: points, error: pointsError } = await supabase
          .from("Point")
          .update({ reset_date: new Date() })
          .eq("house_id", window.resetHouseId)
          .is("reset_date", null);

        if (pointsError) {
          console.error(pointsError);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      const school_id = sessionStorage.getItem("school_id");

      const { data, error } = await supabase
        .from("House")
        .update({ total_points: 0 })
        .eq("house_id", window.resetHouseId)
        .eq("school_id", school_id);
      setPageLoading(true);

      if (error) {
        console.error("Error updating data:", error);
      } else {
        setPageLoading(false);
        console.log("Data updated successfully");
      }

      handleCardCloseClick("reset-houses-modal");
    }
  }

  const [user, setUser] = useState("");
  const [houseArray, setHouse] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  //page redirect function
  let navigate = useNavigate();
  const MovetoNewsfeed = () => {
    let path = `/newsfeed`;
    navigate(path);
  };

  const MovetoSettings = () => {
    let path = `/settings`;
    navigate(path);
  };

  //real-time data fetch
  const [House, setHouseData] = useState([]);
  useEffect(() => {
    const houseChannel = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "House" },
        (payload) => {
          myHouse();
        }
      )
      .subscribe();
    return () => {};
  }, []);

  async function schoolSelectionfetch() {
    const dropdownButtons = document.getElementsByClassName(
      "dropwdown-houselist"
    );
    if (dropdownButtons.length == 0) console.log(dropdownButtons);

    for (let i = 0; i < dropdownButtons.length; i++) {
      const button = dropdownButtons[i];
      const schoolName = button.querySelector("span").textContent;

      if (window.schoolName === schoolName) {
        button.classList.add("selected");
        const imgElement = button.querySelector(".search__dropwdown-icon");
        imgElement.src = houseiconfill;
      } else {
        button.classList.remove("selected");
        const imgElement = button.querySelector(".search__dropwdown-icon");
        imgElement.src = houseicon;
      }
    }
  }

  async function myHouse() {
    const school_id = sessionStorage.getItem("school_id");
    window.tableRows = "";
    const { data: House, error } = await supabase
      .from("House")
      .select("*")
      .eq("school_id", school_id)
      .eq("active", "TRUE")
      .order("house_name", { ascending: true });
    setHouse(House);
    if (House.length == 0) {
      setLoading(true);
      window.tableRows = "no houses are found";
      setLoading(false);
    }
    if (House.length != 0) {
      setLoading(true);
      window.tableRows = House.map((element) => {
        return (
          <div
            class="houses__card"
            data-id="ognisko-modal"
            onClick={() => handleHouseClick(element)}
          >
            <div class="houses__card-image">
              <img src={element.house_crest} alt="Ognisko" />
              <div class="houses__card-shadow"></div>
            </div>
            <div class="houses__card-footer">
              <div class="houses__card-count">
                <img src={crown} alt="Crown icon" />
                <p>{element.total_points}</p>
              </div>
            </div>
          </div>
        );
      });
      setLoading(false);
      setPageLoading(false);
    }
    if (error) {
      console.log("error occured");
    }
  }

  // fetching Houses
  useEffect(() => {
    myFunction();
  }, []);

  const [HousePoints, setHousePoints] = useState(0);
  const [HouseName, setHouseName] = useState(0);
  const [HouseImage, setHouseImage] = useState("");

  async function myFunction() {
    await supabase.auth.getUser().then((value) => {
      if (value.data?.user) {
        setUser(value.data.user);
        sessionStorage.setItem("user_id", value.data.user.id);
      }
    });
    const user_id = sessionStorage.getItem("user_id");
    let { data: Member, error1 } = await supabase
      .from("Member")
      .select("school_id")
      .eq("user_id", user_id)
      .order("lastlogin_date", { ascending: false });

    if (Member.length == 0) {
      setLoading(true);
      setHouse(House);
      window.tableRows = "No school/houses found";
      window.schoolName = "Contact Admin";
      setLoading(false);
      setPageLoading(false);
      window.mode = "Switch to dark Mode";
      window.renderSchoolList = null;
    } else {
      sessionStorage.setItem("school_id", Member[0].school_id);
      const school_id = sessionStorage.getItem("school_id");

      let { data: School } = await supabase
        .from("School")
        .select("school_name")
        .eq("school_id", school_id);
      window.schoolName = School[0].school_name;
      let memberArray = [];
      for (let i = 0; i < Member.length; i++) {
        let member = Member[i];
        let { data: School } = await supabase
          .from("School")
          .select("school_name")
          .eq("school_id", member.school_id);
        memberArray.push(School[0]);
        window.renderSchoolList = renderList(memberArray);
      }

      window.tableRows = "";
      const { data: House, error } = await supabase
        .from("House")
        .select("*")
        .eq("school_id", school_id)
        .eq("active", "TRUE")
        .order("house_name", { ascending: true });
      setHouse(House);
      if (House.length == 0) {
        window.tableRows = "no houses are found";
        setPageLoading(false);
      }
      if (House.length != 0) {
        window.multipleHousesReset = House.map((element) => {
          return (
            <div
              onClick={() => {
                getResetSchoolId(element.house_id, element.house_name);
              }}
              className="reset__slider-item item-reset"
            >
              <p key={element.house_id}>{element.house_name}</p>
            </div>
          );
        });

        setResettingHouseOptions((prevOptions) => {
          const updatedHouse = [...House]; // Create a copy of the House array
          // Add an extra house object to the array
          const extraHouse = {
            house_id: "All Houses",
            house_name: "All Houses",
          };
          updatedHouse.unshift(extraHouse);

          const options = updatedHouse.map((i) => ({
            value: i.house_id,
            label: i.house_name,
          }));

          return {
            ...prevOptions,
            title: options,
          };
        });

        window.multipleHouses = House.map((element) => {
          const checkboxId = `multiCheckbox-${element.house_id}`;
          // Generate unique checkbox ID
          return (
            <div class="multiselect__slider-item">
              <input
                type="checkbox"
                name="myCheckbox"
                key={element.house_id}
                onClick={() => handleMultipleSchool(element.house_id)}
              />
              <div class="multiselect__slider-square add-square">
                <img src={checkbox} alt="icon" />
              </div>
              <label htmlFor={checkboxId}> {element.house_name}</label>
            </div>
          );
        });
        window.multipleHousesSub = House.map((element) => {
          return (
            <div class="multiselect__slider-item">
              <input
                type="checkbox"
                id="multiCheckboxSub"
                name="myCheckbox"
                key={element.house_id}
                onClick={() => handleMultipleSchool(element.house_id)}
              />
              <div class="multiselect__slider-square  subtract-square">
                <img src={checkbox} alt="icon" />
              </div>
              <label class="subtract-item-lable"> {element.house_name}</label>
            </div>
          );
        });
        window.tableRows = House.map((element) => {
          return (
            <div
              class="houses__card"
              data-id="ognisko-modal"
              onClick={() => handleHouseClick(element)}
            >
              <div class="houses__card-image">
                <img src={element.house_crest} alt="Ognisko" />
                <div class="houses__card-shadow"></div>
              </div>
              <div class="houses__card-footer">
                <div class="houses__card-count">
                  <img src={crown} alt="Crown icon" />
                  <p>{element.total_points}</p>
                </div>
              </div>
            </div>
          );
        });
        setPageLoading(false);
        setLoading(false);
      }
      if (error) {
        console.log("error occured");
      }
      lastscreenmodefetch();
      schoolSelectionfetch();
    }
  }

  const [checked, setChecked] = useState();

  useEffect(() => {}, [House]);

  const handleHouseClick = (HouseData) => {
    setIsActive(true);
    handleCardClick("ognisko-modal");
    setHouseName(HouseData.house_name);
    setHousePoints(HouseData.total_points);
    setHouseImage(HouseData.house_crest);
    window.setSchoolId = HouseData.school_id;
    window.setHouseId = HouseData.house_id;
    houseCriteria();
    houseCriteriaSub();
  };

  //select Multiple house and add points
  const [myArray, updateMyArray] = useState([]);
  const [pointsUpdate, updatepoints] = useState(100);
  const handleMultipleSchool = (house) => {
    if (house == "all") {
      updateMyArray((arr) => {
        if (arr.length > 0) {
          return [];
        } else {
          return [...houseArray.map((house) => house.house_id)];
        }
      });
    } else
      updateMyArray((arr) => {
        if (arr.includes(house)) {
          return arr.filter((item) => item !== house);
        } else {
          return [...arr.filter((item) => item !== house), house];
        }
      });
  };

  const pointsonMulti = (points, event) => {
    updatepoints(points);
    const amountItems = document.getElementsByClassName(
      "addpoints__amount-item"
    );
    // Remove "active" class from all elements
    for (let i = 0; i < amountItems.length; i++) {
      amountItems[i].classList.remove("addactive");
    }
    // Add "active" class to the selected element
    event.target.parentNode.classList.add("addactive");
  };
  const pointsubMulti = (points, event) => {
    updatepoints(points);
    const amountsubItems = document.getElementsByClassName(
      "addpoints__amount-item"
    );

    for (let i = 0; i < amountsubItems.length; i++) {
      amountsubItems[i].classList.remove("subactive");
    }

    event.target.parentNode.classList.add("subactive");
  };

  useEffect(() => {}, [myArray]);
  //getting member id:
  const userId = sessionStorage.getItem("user_id");
  const [memberId, setMemberId] = useState();

  //Inserting the points in point table:
  async function pointTable(houseId, points, memberId) {
    // getMemberId();
    const { data: Member, error1 } = await supabase
      .from("Member")
      .select("member_id")
      .eq("user_id", userId);

    const { data, error } = await supabase
      .from("Point")
      .insert([
        {
          house_id: houseId,
          criteria_id: null,
          point_value: points,
          awarded_memberid: Member[0].member_id,
          awarded_date: new Date(),
          reset_date: null,
        },
      ])
      .then((response) => {});
  }

  //adjust multiple houses addpoints button
  async function newPoints() {
    setPageLoading(true);
    const houseIdArray = myArray;
    const housePoint = pointsUpdate;
    const houseData = houseIdArray.map((houseId) => {
      return { house_id: houseId, point: housePoint };
    });

    for (let i = 0; i < houseData.length; i++) {
      const house = houseData[i];

      const existingHouse = await supabase
        .from("House")
        .select("total_points")
        .eq("house_id", house.house_id)
        .single();
      if (existingHouse.error) {
        console.error("Error retrieving existing house:", existingHouse.error);
        continue;
      }
      const currentPoint = existingHouse.data.total_points;
      window.updatedPoint = currentPoint + house.point;
      pointTable(house.house_id, house.point, memberId);

      // Update the current house using Supabase's update function
      const { data, error } = await supabase
        .from("House")
        .update({ total_points: window.updatedPoint })
        .eq("house_id", house.house_id);

      if (error) {
        console.error("Error updating house:", error);
      } else {
        console.log("House updated successfully");
      }
    }
    updateMyArray([]);
    handleCardCloseClick("multi-points-modal");
    setPageLoading(false);
    MultitoggleActiveClass2();
    MultitoggleActiveClass();
  }

  //Condition check for multiple Schools
  function renderList(memberArray) {
    if (memberArray.length > 1) {
      return (
        <ul class="search__dropwdown-houselist">
          <li class="search__dropwdown-item">
            <button
              type="button"
              class="search__dropwdown-link"
              data-id="select-schools-modal"
            >
              <span>
                {memberArray.map((element) => (
                  <li class="search__dropwdown-item">
                    <button
                      type="button"
                      class="search__dropwdown-link dropwdown-houselist"
                      data-id="select-schools-modal"
                      onClick={(e) => handleChangeSchool(element.school_name)}
                    >
                      <img
                        src={houseicon}
                        class="search__dropwdown-icon"
                        alt="house icon"
                      />
                      <span>{element.school_name}</span>
                    </button>
                  </li>
                ))}
              </span>
            </button>
          </li>
        </ul>
      );
    }
    return null;
  }

  // school selection
  const handleChangeSchool = (param) => {
    dropdownRef.current.classList.remove("show");
    dropdownOverlayRef.current.classList.remove("show");
    window.paramSchool_id = param;
    SchoolName();
  };

  async function SchoolName() {
    setPageLoading(true);
    let { data: School, error } = await supabase
      .from("School")
      .select("school_id")
      .eq("school_name", window.paramSchool_id);
    window.schoolName = window.paramSchool_id;

    window.tableRows = "";
    const { data: House, error1 } = await supabase
      .from("House")
      .select("*")
      .eq("school_id", School[0].school_id)
      .eq("active", "TRUE")
      .order("house_name", { ascending: true });
    sessionStorage.setItem("school_id", School[0].school_id);
    setHouse(House);
    if (House.length == 0) {
      setLoading(true);
      window.tableRows = "no houses are found";
      const dropdownButtons = document.getElementsByClassName(
        "dropwdown-houselist"
      );
      for (let i = 0; i < dropdownButtons.length; i++) {
        const button = dropdownButtons[i];
        const schoolName = button.querySelector("span").textContent;

        if (window.schoolName === schoolName) {
          button.classList.add("selected");
          const imgElement = button.querySelector(".search__dropwdown-icon");
          imgElement.src = houseiconfill;
        } else {
          button.classList.remove("selected");
          const imgElement = button.querySelector(".search__dropwdown-icon");
          imgElement.src = houseicon;
        }
      }
      const user_id = sessionStorage.getItem("user_id");
      await supabase
        .from("Member")
        .update({ lastlogin_date: new Date() })
        .eq("user_id", user_id)
        .eq("school_id", School[0].school_id)
        .then((response) => {
          console.log("Update successful");
        });
      setPageLoading(false);
      setLoading(false);
    }
    if (House.length != 0) {
      setLoading(true);
      window.tableRows = House.map((element) => {
        return (
          <div
            class="houses__card"
            data-id="ognisko-modal"
            onClick={() => handleHouseClick(element)}
          >
            <div class="houses__card-image">
              <img src={element.house_crest} alt="Ognisko" />
              <div class="houses__card-shadow"></div>
            </div>
            <div class="houses__card-footer">
              <div class="houses__card-count">
                <img src={crown} alt="Crown icon" />
                <p>{element.total_points}</p>
              </div>
            </div>
          </div>
        );
      });

      setResettingHouseOptions((prevOptions) => {
        const updatedHouse = [...House]; // Create a copy of the House array
        // Add an extra house object to the array
        const extraHouse = {
          house_id: "All Houses",
          house_name: "All Houses",
        };
        updatedHouse.unshift(extraHouse);

        const options = updatedHouse.map((i) => ({
          value: i.house_id,
          label: i.house_name,
        }));

        return {
          ...prevOptions,
          title: options,
        };
      });

      window.multipleHousesReset = House.map((element) => {
        return (
          <div
            onClick={() => {
              getResetSchoolId(element.house_id, element.house_name);
            }}
            className="reset__slider-item item-reset"
          >
            <p key={element.house_id}>{element.house_name}</p>
          </div>
        );
      });
      window.multipleHouses = House.map((element) => {
        const checkboxId = `multiCheckbox-${element.house_id}`;
        // Generate unique checkbox ID
        return (
          <div class="multiselect__slider-item">
            <input
              type="checkbox"
              name="myCheckbox"
              key={element.house_id}
              onClick={() => handleMultipleSchool(element.house_id)}
            />
            <div class="multiselect__slider-square add-square">
              <img src={checkbox} alt="icon" />
            </div>
            <label htmlFor={checkboxId}> {element.house_name}</label>
          </div>
        );
      });
      window.multipleHousesSub = House.map((element) => {
        return (
          <div class="multiselect__slider-item">
            <input
              type="checkbox"
              id="multiCheckboxSub"
              name="myCheckbox"
              key={element.house_id}
              onClick={() => handleMultipleSchool(element.house_id)}
            />
            <div class="multiselect__slider-square  subtract-square">
              <img src={checkbox} alt="icon" />
            </div>
            <label class="subtract-item-lable"> {element.house_name}</label>
          </div>
        );
      });
      const user_id = sessionStorage.getItem("user_id");
      await supabase
        .from("Member")
        .update({ lastlogin_date: new Date() })
        .eq("user_id", user_id)
        .eq("school_id", School[0].school_id)
        .then((response) => {});

      const dropdownButtons = document.getElementsByClassName(
        "dropwdown-houselist"
      );
      for (let i = 0; i < dropdownButtons.length; i++) {
        const button = dropdownButtons[i];
        const schoolName = button.querySelector("span").textContent;

        if (window.schoolName === schoolName) {
          button.classList.add("selected");
          const imgElement = button.querySelector(".search__dropwdown-icon");
          imgElement.src = houseiconfill;
        } else {
          button.classList.remove("selected");
          const imgElement = button.querySelector(".search__dropwdown-icon");
          imgElement.src = houseicon;
        }
      }
      setPageLoading(false);
      setLoading(false);
    }
    lastscreenmodefetch();
  }

  //page redirect function
  const Movetohome = () => {
    let path = `/dashboard`;
    navigate(path);
  };

  //logout function
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  async function handleLogout() {
    try {
      setLoading(true);
      await supabase.auth.getUser().then((value) => {
        if (value.data?.user) {
          setUser(value.data.user);
          sessionStorage.setItem("user_id", value.data.user.id);
        }
      });
      const { data, error } = await supabase.auth.getSession();
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("refreshtoken");
      await supabase.auth.signOut();
      setUser(null);
      console.log("Logout successful");
      history("/");
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setLoading(false);
    }
  }

  //Fetching House Point Criteria Details:
  async function houseCriteria() {
    try {
      const school_id = sessionStorage.getItem("school_id");
      let { data: Criteria, error } = await supabase
        .from("Criteria")
        .select("criteria_name")
        .eq("school_id", school_id)
        .eq("action", "add");
      window.firstCriteriaName = Criteria[0].criteria_name;
      HouseClick(window.firstCriteriaName);
      let { data: Criteriaid } = await supabase
        .from("Criteria")
        .select("criteria_id")
        .eq("criteria_name", window.firstCriteriaName);
      window.firstCriteriaid = Criteriaid[0].criteria_id;
      setBScrollOptions(() => {
        return {
          title: Criteria.map((i) => ({
            value: i.criteria_name,
            label: i.criteria_name,
          })),
        };
      });

      window.criteriaPlusData = Criteria.map((item, index) => {
        return (
          <div
            className={`select__slider-item add ${index === 0 ? "active" : ""}`}
            key={item.criteria_name}
            onClick={() => HouseClick(item.criteria_name)}
          >
            <p>{item.criteria_name}</p>
          </div>
        );
      });
    } catch (error) {
      console.log("cant fetch house point criteria details");
    }
  }

  async function houseCriteriaSub() {
    try {
      const school_id = sessionStorage.getItem("school_id");
      let { data: Criteriaa, error } = await supabase
        .from("Criteria")
        .select("criteria_name")
        .eq("school_id", school_id)
        .eq("action", "subtract");
      window.firstCriteriaNamesub = Criteriaa[0].criteria_name;
      HouseClicksub(window.firstCriteriaNamesub);
      setBScrollMinusOptions(() => {
        return {
          title: Criteriaa.map((i) => ({
            value: i.criteria_name,
            label: i.criteria_name,
          })),
        };
      });
      let { data: Criteriaidd } = await supabase
        .from("Criteria")
        .select("criteria_id")
        .eq("criteria_name", window.firstCriteriaNamesub);
      window.firstCriteriaid1 = Criteriaidd[0].criteria_id;
      window.criteriaMinusData = Criteriaa.map((item, index) => {
        return (
          <div
            className={`select__slider-item ${index === 0 ? "active" : ""}`}
            key={item.criteria_name}
            onClick={() => HouseClicksub(item.criteria_name)}
          >
            <p>{item.criteria_name}</p>
          </div>
        );
      });
      HouseClicksub(window.firstCriteriaNamesub);
    } catch (error) {
      console.log("cant fetch house point criteria details");
    }
  }

  //Handling Criteria Button Click:
  const HouseClick = async (criteriaName) => {
    console.log("addd", criteriaName);
    window.criteriaEname = criteriaName;
    let { data: Criteria1 } = await supabase
      .from("Criteria")
      .select("criteria_id")
      .eq("criteria_name", criteriaName);
    window.CriteriaId = Criteria1[0].criteria_id;

    const ReasonButtons = document.getElementsByClassName(
      "select__slider-item add"
    );
    for (let i = 0; i < ReasonButtons.length; i++) {
      const button = ReasonButtons[i];
      const criteriaaName = button.querySelector("p").textContent;
      if (window.criteriaEname === criteriaaName) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    }
    HousePoint(criteriaName);
  };

  const HouseClicksub = (criteriaName) => {
    window.criteriaEnamesub = criteriaName;
    const ReasonButtons = document.getElementsByClassName(
      "select__slider-item"
    );
    for (let i = 0; i < ReasonButtons.length; i++) {
      const button = ReasonButtons[i];
      const criteriaaNamesub = button.querySelector("p").textContent;

      if (window.criteriaEnamesub === criteriaaNamesub) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    }
    HousePointsub(criteriaName);
  };

  //Getting House Points:
  const [criteriaPlusPoints, setCriteriaPlusPoints] = useState();
  const [criteriaMinusPoints, setCriteriaMinusPoints] = useState();
  async function HousePoint(criteriaName) {
    try {
      let { data: Criteria, error } = await supabase
        .from("Criteria")
        .select("default_point_value")
        .eq("criteria_name", criteriaName);
      const housePoints = Criteria[0].default_point_value;
      window.firstPoint = Criteria[0].default_point_value[0].points;
      setCriteriaPlusPoints(
        housePoints.map((item) => {
          return item.points;
        })
      );
      window.criteriaPlusPoints = housePoints.map((item, index) => {
        return (
          <div
            className={`addpoints__amount-item ${index === 0 ? "active" : ""}`}
            key={index}
          >
            <p onClick={() => addHousePoints(item.points)}>+{item.points}</p>
          </div>
        );
      });

      const addpointsItems = document.querySelectorAll(
        ".addpoints__amount-item"
      );
      addpointsItems.forEach((item, index) => {
        item.classList.toggle("active", index === 0);
      });
    } catch (error) {
      console.log("cant fetch house points");
    }
  }

  async function HousePointsub(criteriaName) {
    try {
      let { data: Criteria, error } = await supabase
        .from("Criteria")
        .select("default_point_value")
        .eq("criteria_name", criteriaName);
      const housePoints = Criteria[0].default_point_value;
      window.firstPoint1 = Criteria[0].default_point_value[0].points;
      setCriteriaMinusPoints(
        housePoints.map((item) => {
          return item.points;
        })
      );
      window.criteriaMinusPoints = housePoints.map((item, index) => {
        return (
          <div
            className={`addpoints__amount-item sub ${
              index === 0 ? "active" : ""
            }`}
            key={index}
          >
            <p onClick={() => subHousePoints(item.points)}>-{item.points}</p>
          </div>
        );
      });

      // Set the active state of the addpoints__amount-item
      const addpointsItems = document.querySelectorAll(
        ".addpoints__amount-item.sub"
      );
      addpointsItems.forEach((item, index) => {
        item.classList.toggle("active", index === 0);
      });
    } catch (error) {
      console.log("cant fetch house points");
    }
  }

  //Add House point
  const addHousePoints = async (addPoint) => {
    window.pointToAdd = addPoint;
    const addPointsItems = document.querySelectorAll(".addpoints__amount p");
    addPointsItems.forEach((item) => {
      if (item.textContent === `+${addPoint}`) {
        item.parentNode.classList.add("active");
      } else {
        item.parentNode.classList.remove("active");
      }
    });
    let { data: Criteria } = await supabase
      .from("Criteria")
      .select("criteria_id")
      .eq("criteria_name", window.criteriaEname);
    window.CriteriaId = Criteria[0].criteria_id;
  };

  //subtract House point
  const subHousePoints = async (addPoint) => {
    window.pointToAdd = -Math.abs(addPoint);
    const addPointsItems = document.querySelectorAll(".subpoints__amount p");
    addPointsItems.forEach((item) => {
      if (item.textContent === `-${addPoint}`) {
        item.parentNode.classList.add("active");
      } else {
        item.parentNode.classList.remove("active");
      }
    });
    let { data: Criteria } = await supabase
      .from("Criteria")
      .select("criteria_id")
      .eq("criteria_name", window.criteriaEnameSub);
    window.CriteriaId = Criteria[0].criteria_id;
  };

  //Add House point
  async function addPoints() {
    setPageLoading(true);
    const user_id = sessionStorage.getItem("user_id");
    let { data: Member } = await supabase
      .from("Member")
      .select("member_id")
      .eq("user_id", user_id);
    window.memberId = Member[0].member_id;
    const existingHouse = await supabase
      .from("House")
      .select("total_points")
      .eq("house_id", window.setHouseId)
      .single();
    if (window.CriteriaId == undefined || null) {
      window.CriteriaId = window.firstCriteriaid;
    }
    if (window.pointToAdd == undefined || null) {
      window.pointToAdd = window.firstPoint;
    }
    const { data2, error2 } = await supabase.from("Point").insert([
      {
        house_id: window.setHouseId,
        criteria_id: window.CriteriaId,
        point_value: window.pointToAdd,
        awarded_memberid: window.memberId,
        awarded_date: new Date(),
        reset_date: null,
      },
    ]);

    if (error2) {
      console.error("Error updating Point", error);
    } else {
      console.log("Point updated successfully");
    }

    const currentPoint = existingHouse.data.total_points;
    window.updatedPoint = currentPoint + window.pointToAdd;
    // Update the current house using Supabase's update function
    const { data, error } = await supabase
      .from("House")
      .update({ total_points: window.updatedPoint })
      .eq("house_id", window.setHouseId);

    if (error) {
      console.error("Error updating house:", error);
    } else {
      console.log("House updated successfully");
      const { data: house } = await supabase
        .from("House")
        .select("total_points")
        .eq("house_id", window.setHouseId);
      setHousePoints(house[0].total_points);
    }
    window.CriteriaId = null;
    window.pointToAdd = null;
    const addpointsItems = document.querySelectorAll(".addpoints__amount-item");
    addpointsItems.forEach((item, index) => {
      item.classList.toggle("active", index === 0);
    });
  }

  //subtract House point
  async function subPoints() {
    setPageLoading(true);
    const user_id = sessionStorage.getItem("user_id");
    let { data: Member } = await supabase
      .from("Member")
      .select("member_id")
      .eq("user_id", user_id);
    window.memberId = Member[0].member_id;
    const existingHouse = await supabase
      .from("House")
      .select("total_points")
      .eq("house_id", window.setHouseId)
      .single();

    if (window.CriteriaId == undefined || null) {
      window.CriteriaId = window.firstCriteriaid1;
    }
    if (window.pointToAdd == undefined || null) {
      window.pointToAdd = -Math.abs(window.firstPoint1);
    }
    const { data2, error2 } = await supabase.from("Point").insert([
      {
        house_id: window.setHouseId,
        criteria_id: window.CriteriaId,
        point_value: window.pointToAdd,
        awarded_memberid: window.memberId,
        awarded_date: new Date(),
        reset_date: null,
      },
    ]);
    if (error2) {
      console.error("Error updating Point", error);
    } else {
      console.log("Point updated successfully");
    }

    const currentPoint = existingHouse.data.total_points;
    window.updatedPoint = currentPoint + window.pointToAdd;
    // Update the current house using Supabase's update function
    const { data, error } = await supabase
      .from("House")
      .update({ total_points: window.updatedPoint })
      .eq("house_id", window.setHouseId);

    if (error) {
      console.error("Error updating house:", error);
    } else {
      console.log("House updated successfully");
      const { data: house } = await supabase
        .from("House")
        .select("total_points")
        .eq("house_id", window.setHouseId);
      setHousePoints(house[0].total_points);
    }
    window.CriteriaId = null;
    window.pointToAdd = null;
    const addpointsItems = document.querySelectorAll(
      ".addpoints__amount-item.sub"
    );
    addpointsItems.forEach((item, index) => {
      item.classList.toggle("active", index === 0);
    });
  }

  //scroll
  function handleAllHousesSubtractChange(event) {
    const checkboxessub = document.querySelectorAll(
      '.multiselect__slider input[type="checkbox"]:not(#subtract-all-house)'
    );

    checkboxessub.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });
  }
  function handleAllHousesaddChange(event) {
    const checkboxessub = document.querySelectorAll(
      '.multiselect__slider input[type="checkbox"]:not(#add-all-house)'
    );

    checkboxessub.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });
  }

  const scrollContainerRef4 = useRef(null);
  const scrollContainerRef1 = useRef(null);
  const scrollContainerRef2 = useRef(null);
  const scrollContainerRef3 = useRef(null);

  const handleWheel1 = (event) => {
    const scrollContainer1 = scrollContainerRef1.current;
    const delta = Math.max(-1, Math.min(1, event.deltaY)); // Normalize the delta value
    const scrollAmount = delta * 100; // Adjust the scrolling speed as needed
    const currentPosition = scrollContainer1.scrollLeft;
    const newPosition = currentPosition + scrollAmount;

    scrollSmoothly1(scrollContainer1, currentPosition, newPosition);
    event.preventDefault(); // Prevent default page scrolling
  };

  const scrollSmoothly1 = (element, from, to) => {
    let start = null;
    const duration = 300; // Duration of the smooth scrolling animation in milliseconds
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const scrollPosition = easeInOutQuad(progress, from, to - from, duration);
      element.scrollLeft = scrollPosition;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const handleWheel2 = (event) => {
    const scrollContainer2 = scrollContainerRef2.current;
    const delta = Math.max(-1, Math.min(1, event.deltaY)); // Normalize the delta value
    const scrollAmount = delta * 100; // Adjust the scrolling speed as needed
    const currentPosition = scrollContainer2.scrollLeft;
    const newPosition = currentPosition + scrollAmount;
    scrollSmoothly2(scrollContainer2, currentPosition, newPosition);
    event.preventDefault(); // Prevent default page scrolling
  };

  const scrollSmoothly2 = (element, from, to) => {
    let start = null;
    const duration = 300; // Duration of the smooth scrolling animation in milliseconds
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const scrollPosition = easeInOutQuad(progress, from, to - from, duration);
      element.scrollLeft = scrollPosition;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const handleWheel3 = (event) => {
    const scrollContainer3 = scrollContainerRef3.current;
    const delta = Math.max(-1, Math.min(1, event.deltaY)); // Normalize the delta value
    const scrollAmount = delta * 100; // Adjust the scrolling speed as needed
    const currentPosition = scrollContainer3.scrollLeft;
    const newPosition = currentPosition + scrollAmount;
    scrollSmoothly3(scrollContainer3, currentPosition, newPosition);
    event.preventDefault(); // Prevent default page scrolling
  };

  const scrollSmoothly3 = (element, from, to) => {
    let start = null;
    const duration = 300; // Duration of the smooth scrolling animation in milliseconds
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const scrollPosition = easeInOutQuad(progress, from, to - from, duration);
      element.scrollLeft = scrollPosition;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const handleWheel4 = (event) => {
    const scrollContainer4 = scrollContainerRef4.current;
    const delta = Math.max(-1, Math.min(1, event.deltaY)); // Normalize the delta value
    const scrollAmount = delta * 100; // Adjust the scrolling speed as needed
    const currentPosition = scrollContainer4.scrollLeft;
    const newPosition = currentPosition + scrollAmount;
    scrollSmoothly4(scrollContainer4, currentPosition, newPosition);
    event.preventDefault(); // Prevent default page scrolling
  };

  const scrollSmoothly4 = (element, from, to) => {
    let start = null;
    const duration = 300; // Duration of the smooth scrolling animation in milliseconds
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const scrollPosition = easeInOutQuad(progress, from, to - from, duration);
      element.scrollLeft = scrollPosition;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  return (
    <div className="body">
      {pageLoading ? <PageLoader /> : ""}
      <div class="layout">
        {/* <!-- ================ Sidebar Start ================== --> */}
        <aside class="sidebar">
          {/* <!-- Menu --> */}
          <div class="sidebar__menu">
            <div class="sidebar__menu-brand">
              <a onClick={Movetohome} class="sidebar__menu-logo">
                <img src={logo} alt="Logo" />
              </a>
              <h5 class="sidebar__menu-title">House Point System</h5>
            </div>
            <nav class="sidebar__menu-nav">
              <ul class="sidebar__menu-list">
                <li class="sidebar__menu-item">
                  <a onClick={Movetohome} class="sidebar__menu-link active">
                    <span class="sidebar__menu-icon">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.4628 8.69992C19.4623 8.69947 19.4618 8.69901 19.4614 8.69855L11.3021 0.539606C10.9544 0.19167 10.492 0 10.0001 0C9.50828 0 9.04589 0.191517 8.69796 0.539453L0.542984 8.69428C0.540237 8.69702 0.53749 8.69992 0.534743 8.70267C-0.179441 9.42097 -0.17822 10.5864 0.538253 11.3029C0.865587 11.6304 1.29791 11.8201 1.76015 11.8399C1.77892 11.8417 1.79784 11.8426 1.81692 11.8426H2.14212V17.8471C2.14212 19.0353 3.10886 20.002 4.29733 20.002H7.48949C7.81301 20.002 8.07549 19.7397 8.07549 19.416V14.7085C8.07549 14.1663 8.51651 13.7253 9.05871 13.7253H10.9415C11.4837 13.7253 11.9248 14.1663 11.9248 14.7085V19.416C11.9248 19.7397 12.1871 20.002 12.5108 20.002H15.7029C16.8914 20.002 17.8581 19.0353 17.8581 17.8471V11.8426H18.1597C18.6514 11.8426 19.1138 11.6511 19.4618 11.3032C20.1791 10.5855 20.1794 9.41808 19.4628 8.69992Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span>Houses</span>
                  </a>
                </li>

                <li class="sidebar__menu-item">
                  <a onClick={MovetoNewsfeed} class="sidebar__menu-link">
                    <span class="sidebar__menu-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2ZM2.72727 3.72728C2.72727 3.17499 3.17499 2.72728 3.72727 2.72728H6.27273C6.82501 2.72728 7.27273 3.17499 7.27273 3.72728V6.27273C7.27273 6.82502 6.82501 7.27273 6.27273 7.27273H3.72727C3.17499 7.27273 2.72727 6.82502 2.72727 6.27273V3.72728ZM9.09091 2.72728C8.58883 2.72728 8.18182 3.13429 8.18182 3.63637C8.18182 4.13844 8.58883 4.54546 9.09091 4.54546H14.5455C15.0475 4.54546 15.4545 4.13844 15.4545 3.63637C15.4545 3.13429 15.0475 2.72728 14.5455 2.72728H9.09091ZM8.18182 6.36364C8.18182 5.86156 8.58883 5.45455 9.09091 5.45455H16.3636C16.8657 5.45455 17.2727 5.86156 17.2727 6.36364C17.2727 6.86572 16.8657 7.27273 16.3636 7.27273H9.09091C8.58883 7.27273 8.18182 6.86572 8.18182 6.36364ZM3.72727 9.09091C3.17499 9.09091 2.72727 9.53863 2.72727 10.0909V16.2727C2.72727 16.825 3.17499 17.2727 3.72727 17.2727H16.2727C16.825 17.2727 17.2727 16.825 17.2727 16.2727V10.0909C17.2727 9.53863 16.825 9.09091 16.2727 9.09091H3.72727Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span>Feeds</span>
                  </a>
                </li>

                <li class="sidebar__menu-item">
                  <a onClick={MovetoSettings} class="sidebar__menu-link">
                    <span class="sidebar__menu-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          d="M19.7406 17.8749C18.3412 15.4642 16.1012 13.6548 13.45 12.7937C14.7208 12.038 15.7083 10.886 16.2608 9.5147C16.8134 8.14338 16.9005 6.62856 16.5086 5.20297C16.1168 3.77737 15.2677 2.51986 14.0919 1.62361C12.916 0.727355 11.4785 0.241943 10 0.241943C8.52153 0.241943 7.08396 0.727355 5.90812 1.62361C4.73228 2.51986 3.88323 3.77737 3.49138 5.20297C3.09954 6.62856 3.18659 8.14338 3.73915 9.5147C4.29172 10.886 5.27923 12.038 6.55 12.7937C3.89884 13.6548 1.65881 15.4642 0.259373 17.8749C0.190665 17.9879 0.154327 18.1177 0.154327 18.2499C0.154327 18.3822 0.190665 18.5119 0.259373 18.6249C0.323476 18.7398 0.417406 18.8353 0.531257 18.9013C0.645107 18.9673 0.774659 19.0014 0.906248 18.9999H19.0937C19.2253 19.0014 19.3549 18.9673 19.4687 18.9013C19.5826 18.8353 19.6765 18.7398 19.7406 18.6249C19.8093 18.5119 19.8457 18.3822 19.8457 18.2499C19.8457 18.1177 19.8093 17.9879 19.7406 17.8749Z"
                        />
                      </svg>
                    </span>
                    <span>Profile</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          {/* <!-- Logout link --> */}
          <div class="sidebar__logout">
            <a
              onClick={() => handleCardClick("logout-modal")}
              class="sidebar__logout-link"
            >
              <img src={logouticon} alt="icon" />
              <span>Log out</span>
            </a>
          </div>
        </aside>
        {/* <!-- ================ Sidebar End ================== --> */}

        <main class="main-section">
          {/* <!-- ================ Search Start ================== --> */}
          <section class="search home-search">
            <h3 className="logohps">
              <a onClick={Movetohome} class="sidebar__menu-logo mobile-nav">
                <img src={logo} alt="Logo" />
              </a>
              <div className="brand-name">
                <span>HPS</span>{" "}
              </div>
            </h3>
            <div class="search__dropwdown">
              <button
                type="button"
                class="search__dropwdown-btn"
                onClick={toggleDropdown}
              >
                <img
                  class="search__dropwdown-imgs"
                  src={dotsicon}
                  alt="Dots icon"
                />
              </button>
              <div class="search__dropwdown-dropwdown" ref={dropdownRef}>
                <div class="search__dropwdown-header">
                  <h3>More options</h3>
                  <button
                    class="search__dropwdown-close"
                    onClick={() =>
                      handleCardCloseClick("search__dropwdown-dropwdown")
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleCardCloseClick("dropdown-backdrop")}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.94663 16.6131L8.24543 9.99935L1.94663 3.38561C1.53063 2.94881 1.58684 2.29121 2.07217 1.91681C2.5575 1.54241 3.28816 1.593 3.70416 2.02979L10.0013 8.64179L16.2984 2.02979C16.7144 1.593 17.4451 1.54241 17.9304 1.91681C18.4158 2.29121 18.472 2.94881 18.056 3.38561L11.7572 9.99935L18.056 16.6131C18.472 17.0499 18.4158 17.7075 17.9304 18.0819C17.4451 18.4563 16.7144 18.4057 16.2984 17.9689L10.0013 11.3569L3.70416 17.9689C3.28816 18.4057 2.5575 18.4563 2.07217 18.0819C1.58684 17.7075 1.53063 17.0499 1.94663 16.6131Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
                <ul class="search__dropwdown-list">
                  <div class="search__dropwdown-arrow">
                    <div class="search__dropwdown-rectangle"></div>
                  </div>

                  <li class="search__dropwdown-item">
                    <button
                      type="button"
                      class="search__dropwdown-link"
                      data-id="multi-points-modal"
                      onClick={() => handleCardClick("multi-points-modal")}
                    >
                      <img
                        src={check}
                        class="search__dropwdown-icon"
                        alt="Check icon"
                      />
                      <span>Adjust Points For Multiple Houses</span>
                    </button>
                  </li>

                  <li class="search__dropwdown-item">
                    <button
                      type="button"
                      class="search__dropwdown-link"
                      data-id="reset-houses-modal"
                      onClick={() => handleCardClick("reset-houses-modal")}
                    >
                      <img
                        src={reset}
                        class="search__dropwdown-icon"
                        alt="Reset icon"
                      />
                      <span>Reset House Points</span>
                    </button>
                  </li>

                  <li class="search__dropwdown-item">
                    <button
                      type="button"
                      class="search__dropwdown-link"
                      id="dark-light-mode"
                      onClick={onClickScreenMode}
                    >
                      <img
                        src={dark}
                        class="search__dropwdown-icon"
                        alt="dark mode icon"
                      />
                      <span>{window.mode}</span>
                    </button>
                  </li>
                </ul>
                {window.renderSchoolList}
              </div>
            </div>
          </section>
          {/* <!-- ================ Search End ================== --> */}

          {/* <!-- ================ Houses Start ================== --> */}
          <section class="houses">
            <div class="houses-wrapper">
              <h3 class="text-center houses-title">{window.schoolName}</h3>
              <div class="houses__cards">{window.tableRows}</div>
            </div>
          </section>
          {/* <!-- ================ Houses End ================== --> */}
        </main>
      </div>

      {/* <!-- ================ Mobile Menu Start ================== --> */}
      <nav class="mobile-menu">
        <ul class="mobile-menu__list">
          <li class="mobile-menu__list-item">
            <a onClick={Movetohome} class="mobile-menu__list-link active">
              <span class="icon">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4628 8.69992C19.4623 8.69947 19.4618 8.69901 19.4614 8.69855L11.3021 0.539606C10.9544 0.19167 10.492 0 10.0001 0C9.50828 0 9.04589 0.191517 8.69796 0.539453L0.542984 8.69428C0.540237 8.69702 0.53749 8.69992 0.534743 8.70267C-0.179441 9.42097 -0.17822 10.5864 0.538253 11.3029C0.865587 11.6304 1.29791 11.8201 1.76015 11.8399C1.77892 11.8417 1.79784 11.8426 1.81692 11.8426H2.14212V17.8471C2.14212 19.0353 3.10886 20.002 4.29733 20.002H7.48949C7.81301 20.002 8.07549 19.7397 8.07549 19.416V14.7085C8.07549 14.1663 8.51651 13.7253 9.05871 13.7253H10.9415C11.4837 13.7253 11.9248 14.1663 11.9248 14.7085V19.416C11.9248 19.7397 12.1871 20.002 12.5108 20.002H15.7029C16.8914 20.002 17.8581 19.0353 17.8581 17.8471V11.8426H18.1597C18.6514 11.8426 19.1138 11.6511 19.4618 11.3032C20.1791 10.5855 20.1794 9.41808 19.4628 8.69992Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span>Houses</span>
            </a>
          </li>

          <li class="mobile-menu__list-item">
            <a onClick={MovetoNewsfeed} class="mobile-menu__list-link">
              <span class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2ZM2.72727 3.72728C2.72727 3.17499 3.17499 2.72728 3.72727 2.72728H6.27273C6.82501 2.72728 7.27273 3.17499 7.27273 3.72728V6.27273C7.27273 6.82502 6.82501 7.27273 6.27273 7.27273H3.72727C3.17499 7.27273 2.72727 6.82502 2.72727 6.27273V3.72728ZM9.09091 2.72728C8.58883 2.72728 8.18182 3.13429 8.18182 3.63637C8.18182 4.13844 8.58883 4.54546 9.09091 4.54546H14.5455C15.0475 4.54546 15.4545 4.13844 15.4545 3.63637C15.4545 3.13429 15.0475 2.72728 14.5455 2.72728H9.09091ZM8.18182 6.36364C8.18182 5.86156 8.58883 5.45455 9.09091 5.45455H16.3636C16.8657 5.45455 17.2727 5.86156 17.2727 6.36364C17.2727 6.86572 16.8657 7.27273 16.3636 7.27273H9.09091C8.58883 7.27273 8.18182 6.86572 8.18182 6.36364ZM3.72727 9.09091C3.17499 9.09091 2.72727 9.53863 2.72727 10.0909V16.2727C2.72727 16.825 3.17499 17.2727 3.72727 17.2727H16.2727C16.825 17.2727 17.2727 16.825 17.2727 16.2727V10.0909C17.2727 9.53863 16.825 9.09091 16.2727 9.09091H3.72727Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span>Feed</span>
            </a>
          </li>

          <li class="mobile-menu__list-item">
            <a onClick={MovetoSettings} class="mobile-menu__list-link">
              <span class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    d="M19.7406 17.8749C18.3412 15.4642 16.1012 13.6548 13.45 12.7937C14.7208 12.038 15.7083 10.886 16.2608 9.5147C16.8134 8.14338 16.9005 6.62856 16.5086 5.20297C16.1168 3.77737 15.2677 2.51986 14.0919 1.62361C12.916 0.727355 11.4785 0.241943 10 0.241943C8.52153 0.241943 7.08396 0.727355 5.90812 1.62361C4.73228 2.51986 3.88323 3.77737 3.49138 5.20297C3.09954 6.62856 3.18659 8.14338 3.73915 9.5147C4.29172 10.886 5.27923 12.038 6.55 12.7937C3.89884 13.6548 1.65881 15.4642 0.259373 17.8749C0.190665 17.9879 0.154327 18.1177 0.154327 18.2499C0.154327 18.3822 0.190665 18.5119 0.259373 18.6249C0.323476 18.7398 0.417406 18.8353 0.531257 18.9013C0.645107 18.9673 0.774659 19.0014 0.906248 18.9999H19.0937C19.2253 19.0014 19.3549 18.9673 19.4687 18.9013C19.5826 18.8353 19.6765 18.7398 19.7406 18.6249C19.8093 18.5119 19.8457 18.3822 19.8457 18.2499C19.8457 18.1177 19.8093 17.9879 19.7406 17.8749Z"
                  />
                </svg>
              </span>
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </nav>
      {/* <!-- ================ Mobile Menu End ================== --> */}
      <div class="dropdown-backdrop" ref={dropdownOverlayRef}></div>

      <div>
        {/* <!-- ================ Ognisko Modal Start ================== --> */}
        <div class="backdrop ognisko-modal">
          <div class="modal">
            {pageLoading ? <PageLoader /> : ""}
            <div class="modal__header">
              <div className="d-flex">
                <img
                  src={HouseImage}
                  class="modal__header-image"
                  alt="ognisko image"
                />{" "}
                <span className="modal__house_name">
                  <p>{HouseName}</p>
                </span>
              </div>

              <button
                type="button"
                class="modal__header-btn"
                data-toggle="ognisko-modal"
                onClick={() => handleCardCloseClick("ognisko-modal")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.94663 16.6131L8.24543 9.99935L1.94663 3.38561C1.53063 2.94881 1.58684 2.29121 2.07217 1.91681C2.5575 1.54241 3.28816 1.593 3.70416 2.02979L10.0013 8.64179L16.2984 2.02979C16.7144 1.593 17.4451 1.54241 17.9304 1.91681C18.4158 2.29121 18.472 2.94881 18.056 3.38561L11.7572 9.99935L18.056 16.6131C18.472 17.0499 18.4158 17.7075 17.9304 18.0819C17.4451 18.4563 16.7144 18.4057 16.2984 17.9689L10.0013 11.3569L3.70416 17.9689C3.28816 18.4057 2.5575 18.4563 2.07217 18.0819C1.58684 17.7075 1.53063 17.0499 1.94663 16.6131Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div class="modal__body">
              <div class="points__current">
                <p class="text-center">Current Points</p>
                <div class="points__current-score">
                  <img src={crown} alt="Crown icon" />
                  <p>{HousePoints}</p>
                </div>
              </div>

              <form class="addpoints">
                <div class="addpoints__tabs">
                  <div class="addpoints__tabs-header ognisko-tab-header">
                    <button
                      type="button"
                      id="add"
                      class={`addpoints__tabs-btn ${isActive ? "active" : ""}`}
                      data-toggle="ognisko-add-points-tab"
                      onClick={() => {
                        toggleActiveClass();
                      }}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      id="subtract"
                      data-toggle="ognisko-subtract-points-tab"
                      class={`addpoints__tabs-btn subtract-btn ${
                        !isActive ? "active" : ""
                      }`}
                      onClick={() => {
                        toggleActiveClass2();
                      }}
                    >
                      Subtract
                    </button>
                  </div>

                  <div class="addpoints__tabs-content">
                    <div
                      class={`addpoints__tabs-tab ognisko-add-points-tab  ognisko-tab-content ${
                        isActive ? "active" : ""
                      }`}
                    >
                      <div class="addpoints__amount">
                        <p class="addpoints__amount-title">Select Points</p>
                        <div class="addpoints__amount-slider-wrapper no-scrollbar">
                          <div
                            class="addpoints__amount-slider"
                            ref={scrollContainerRef2}
                            style={{
                              width: "100%",
                              overflowX: "scroll",
                            }}
                            onWheel={handleWheel2}
                          >
                            {window.criteriaPlusPoints}
                          </div>
                        </div>
                      </div>
                      <div class="select__slider-container no-scrollbar">
                        <div class="select__slider">
                          <div class="select__slider-item">
                            <p>Choose Reason</p>
                          </div>
                          <div className="pickers-container">
                            <Picker
                              optionGroups={bScrollOptions}
                              valueGroups={range}
                              onChange={handleBetterScrollChange}
                              key={range.criteria_name}
                            />
                          </div>
                        </div>
                      </div>

                      <p
                        type="submit"
                        class="btn btn-primary add"
                        onClick={addPoints}
                      >
                        Add Points
                      </p>
                    </div>
                    <div
                      class={`addpoints__tabs-tab ognisko-subtract-points-tab  ognisko-tab-content ${
                        !isActive ? "active" : ""
                      }`}
                    >
                      <div class="subpoints__amount subtract-amount">
                        <p class="subpoints__amount-title">Select Points</p>
                        <div class="subpoints__amount-slider-wrapper no-scrollbar">
                          <div
                            class="addpoints__amount-slider subtract-slider"
                            ref={scrollContainerRef3}
                            style={{
                              width: "100%",
                              overflowX: "scroll",
                            }}
                            onWheel={handleWheel3}
                          >
                            {window.criteriaMinusPoints}
                          </div>
                        </div>
                      </div>

                      <div class="select__slider-container no-scrollbar">
                        <div class="select__slider subtract-slider subs">
                          <div class="select__slider-item ">
                            <p>Choose Reason</p>
                          </div>
                          <Picker
                            optionGroups={bScrollMinusOptions}
                            valueGroups={subRange}
                            onChange={handleBetterScrollMinusChange}
                            key={subRange.criteria_name}
                          />
                          {/* {"You selected " + range.title} */}
                        </div>
                      </div>

                      <p
                        type="submit"
                        class="btn btn-primary add"
                        onClick={subPoints}
                      >
                        Subtract Points
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- ================ Ognisko Modal Start ================== --> */}
        {/* logout  */}

        {/* logoutmodal */}
        <div className="backdrop logout-modal">
          <div className="modal">
            {pageLoading ? <PageLoader /> : ""}
            <div className="modal__header upload-post-header">
              <h4></h4>
              <button
                type="button"
                className="modal__header-btn"
                data-toggle="logout-modal"
                onClick={() => handleCardCloseClick("logout-modal")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.94663 16.6131L8.24543 9.99935L1.94663 3.38561C1.53063 2.94881 1.58684 2.29121 2.07217 1.91681C2.5575 1.54241 3.28816 1.593 3.70416 2.02979L10.0013 8.64179L16.2984 2.02979C16.7144 1.593 17.4451 1.54241 17.9304 1.91681C18.4158 2.29121 18.472 2.94881 18.056 3.38561L11.7572 9.99935L18.056 16.6131C18.472 17.0499 18.4158 17.7075 17.9304 18.0819C17.4451 18.4563 16.7144 18.4057 16.2984 17.9689L10.0013 11.3569L3.70416 17.9689C3.28816 18.4057 2.5575 18.4563 2.07217 18.0819C1.58684 17.7075 1.53063 17.0499 1.94663 16.6131Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="modal__body upload-post-modal">
              <div class="post-box text-center">
                <h4 className="mb-3">Are you sure you want to logout</h4>

                <hr className="mt-25 b-line" />
                <p className="mt-2 cursorpointer" onClick={handleLogout}>
                  logout
                </p>
                <hr className="mt-25 b-line" />
                <p
                  className="mt-2 cursorpointer"
                  onClick={() => handleCardCloseClick("logout-modal")}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* logout modal */}

        {/* logout */}
        {/* <!-- ================ reset-houses-modal  Start ================== --> */}
        <div className="backdrop reset-houses-modal">
          <div className="modal">
            {pageLoading ? <PageLoader /> : ""}
            <div className="modal__header">
              <h3>Reset House Points</h3>
              <button
                type="button"
                className="modal__header-btn"
                data-toggle="reset-houses-modal"
                onClick={() => handleCardCloseClick("reset-houses-modal")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.94663 16.6131L8.24543 9.99935L1.94663 3.38561C1.53063 2.94881 1.58684 2.29121 2.07217 1.91681C2.5575 1.54241 3.28816 1.593 3.70416 2.02979L10.0013 8.64179L16.2984 2.02979C16.7144 1.593 17.4451 1.54241 17.9304 1.91681C18.4158 2.29121 18.472 2.94881 18.056 3.38561L11.7572 9.99935L18.056 16.6131C18.472 17.0499 18.4158 17.7075 17.9304 18.0819C17.4451 18.4563 16.7144 18.4057 16.2984 17.9689L10.0013 11.3569L3.70416 17.9689C3.28816 18.4057 2.5575 18.4563 2.07217 18.0819C1.58684 17.7075 1.53063 17.0499 1.94663 16.6131Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="modal__body reset-points-body">
              <h2>Reset House Points</h2>
              <form className="addpoints">
                <Picker
                  optionGroups={resettingHouseOptions}
                  valueGroups={resettingHouseRange}
                  onChange={handleResettingHouseChange}
                  key={resettingHouseRange.house_id}
                />
                <p
                  onClick={() => {
                    ResetHouses();
                  }}
                  className="btn btn-primary"
                >
                  Reset Points
                </p>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- ================ Select schools Modal Start ================== --> */}

        {/* <!-- ================ Multiple Points Modal Start ================ --> */}
        <div class="backdrop multi-points-modal">
          <div class="modal">
            {pageLoading ? <PageLoader /> : ""}
            <div class="modal__header">
              <h3>Multiple House Points</h3>
              <button
                type="button"
                class="modal__header-btn"
                onClick={() => handleCardCloseClick("multi-points-modal")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.94663 16.6131L8.24543 9.99935L1.94663 3.38561C1.53063 2.94881 1.58684 2.29121 2.07217 1.91681C2.5575 1.54241 3.28816 1.593 3.70416 2.02979L10.0013 8.64179L16.2984 2.02979C16.7144 1.593 17.4451 1.54241 17.9304 1.91681C18.4158 2.29121 18.472 2.94881 18.056 3.38561L11.7572 9.99935L18.056 16.6131C18.472 17.0499 18.4158 17.7075 17.9304 18.0819C17.4451 18.4563 16.7144 18.4057 16.2984 17.9689L10.0013 11.3569L3.70416 17.9689C3.28816 18.4057 2.5575 18.4563 2.07217 18.0819C1.58684 17.7075 1.53063 17.0499 1.94663 16.6131Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div class="modal__body multi-points-body">
              <h2>Multiple House Points</h2>
              <form class="addpoints">
                <div class="addpoints__tabs">
                  <div class="addpoints__tabs-header multi-points-tab-header">
                    <button
                      type="button"
                      class={`addpoints__tabs-btn ${isActive ? "active" : ""}`}
                      onClick={MultitoggleActiveClass}
                      data-toggle="multi-add-points-tab"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      class={`addpoints__tabs-btn subtract-btn ${
                        !isActive ? "active" : ""
                      }`}
                      data-toggle="multi-subtract-points-tab"
                      onClick={MultitoggleActiveClass2}
                    >
                      Subtract
                    </button>
                  </div>

                  <div class="addpoints__tabs-content">
                    <div
                      class={`addpoints__tabs-tab multi-add-points-tab multi-points-tab-content ${
                        isActive ? "active" : ""
                      }`}
                    >
                      <div class="addpoints__amount">
                        <p class="addpoints__amount-title">Select Points</p>

                        <div class="addpoints__amount-slider-wrapper no-scrollbar">
                          <div
                            class="addpoints__amount-slider"
                            ref={scrollContainerRef4}
                            style={{
                              width: "100%",
                              overflowX: "scroll",
                            }}
                            onWheel={handleWheel4}
                          >
                            <div class="addpoints__amount-item addactive">
                              <p onClick={(event) => pointsonMulti(100, event)}>
                                +100
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p onClick={(event) => pointsonMulti(200, event)}>
                                +200
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p onClick={(event) => pointsonMulti(300, event)}>
                                +300
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p onClick={(event) => pointsonMulti(400, event)}>
                                +400
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p onClick={(event) => pointsonMulti(500, event)}>
                                +500
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="multiselect__slider-container no-scrollbar">
                        <div class="multiselect__slider">
                          <div class="multiselect__slider-item">
                            <input
                              type="checkbox"
                              id="add-all-house"
                              onChange={handleAllHousesaddChange}
                              onClick={() => handleMultipleSchool("all")}
                            />
                            <div class="multiselect__slider-square">
                              <img src={checkbox} alt="icon" />
                            </div>
                            <label for="add-all-house">All Houses</label>
                          </div>

                          {window.multipleHouses}
                        </div>
                      </div>

                      <p
                        type="submit"
                        class="btn btn-primary add"
                        onClick={newPoints}
                      >
                        Add Points
                      </p>
                    </div>
                    <div
                      class={`addpoints__tabs-tab multi-points-tab-content multi-subtract-points-tab ${
                        !isActive ? "active" : ""
                      }`}
                    >
                      <div class="addpoints__amount subtract-amount">
                        <p class="addpoints__amount-title">Select Points</p>

                        <div class="addpoints__amount-slider-wrapper no-scrollbar">
                          <div
                            class="addpoints__amount-slider subtract-slider"
                            ref={scrollContainerRef1}
                            style={{
                              width: "100%",
                              overflowX: "scroll",
                            }}
                            onWheel={handleWheel1}
                          >
                            <div class="addpoints__amount-item subactive">
                              <p
                                onClick={(event) => pointsubMulti(-100, event)}
                              >
                                -100
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p
                                onClick={(event) => pointsubMulti(-200, event)}
                              >
                                -200
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p
                                onClick={(event) => pointsubMulti(-300, event)}
                              >
                                -300
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p
                                onClick={(event) => pointsubMulti(-400, event)}
                              >
                                -400
                              </p>
                            </div>
                            <div class="addpoints__amount-item">
                              <p
                                onClick={(event) => pointsubMulti(-500, event)}
                              >
                                -500
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="multiselect__slider-container no-scrollbar">
                        <div class="multiselect__slider">
                          <div class="multiselect__slider-item">
                            <input
                              type="checkbox"
                              id="subtract-all-house"
                              onChange={handleAllHousesSubtractChange}
                              onClick={() => handleMultipleSchool("all")}
                            />
                            <div class="multiselect__slider-square subtract-square">
                              <img src={checkbox} alt="icon" />
                            </div>
                            <label
                              class="subtract-item-lable"
                              for="subtract-all-house"
                            >
                              All Houses
                            </label>
                          </div>

                          {window.multipleHousesSub}
                        </div>
                      </div>

                      <p
                        type="submit"
                        class="btn btn-primary add"
                        onClick={newPoints}
                      >
                        Subtract Points
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- ================ Multiple Points Modal End ================== --> */}
      </div>
    </div>
  );
}

export default Dashboard;
