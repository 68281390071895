import React from "react";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <div class="center-body">
        <div class="hps-loader"></div>
      </div>
    </div>
  );
};

export default PageLoader;
