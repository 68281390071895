import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { supabase } from "../supabase";

const PrivateRoute = ({ children }) => {
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        setAuthed(true);
      } else {
        setAuthed(false);
      }
    });
  }, []);

  return authed ? children : <Navigate to="" />;
};

export default PrivateRoute;
