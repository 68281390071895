import React, { useState } from "react";
import ReactDOM from "react-dom/client";
//import { HashRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Forgotpassword from "./components/Forgotpassword";
import Dashboard from "./components/Dashboard";
import Resetpassword from "./components/Resetpassword";
import Newsfeed from "./components/Newsfeed";
import Signin from "./components/Signin";
import Setting from "./components/Settings";
import Expirepage from "./components/Expirepage";
import TvDashboard from "./pages/TvDashboard";
import TvScreen from "./pages/TvScreen";
import TvCards from "./pages/TvCards";
import CarouselCards from "./pages/CarouselCards";
import ImageUpload from "./components/ImageUpload";
import PostingSpinner from "./components/PostingSpinner";
import { Capacitor } from "@capacitor/core";
import Dropzone from "./components/Dropzone";
import PrivateRoute from "./components/PrivateRoute";

const isAutheticated = false;
const root = ReactDOM.createRoot(document.getElementById("root"));
const LightTheme = React.lazy(() => import("./platform/MobPlatform"));
const DarkTheme = React.lazy(() => import("./platform/WebPlatform"));

const ThemeSelector = ({ children }) => {
  let platform = Capacitor.isNativePlatform;
  return (
    <>
      <React.Suspense fallback={<></>}>
        {platform != "() => getPlatform() !== 'web'" && <LightTheme />}
        {platform == "() => getPlatform() !== 'web'" && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

root.render(
  <ThemeSelector>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route exact path="/" element={<Signin />} />
          <Route exact path="/*" element={<Signin />} />
          <Route path="expirelink" element={<Expirepage />} />
          <Route path="signin" element={<Signin />} />
          <Route path="forgotpassword" element={<Forgotpassword />} />
          <Route path="reset-password" element={<Resetpassword />} />
          <Route path="viewer" element={<TvDashboard />} />
          <Route path="activity" element={<CarouselCards />} />
          <Route path="tvscreen" element={<TvScreen />} />
          <Route path="tvcards" element={<TvCards />} />
          <Route path="imageupload" element={<ImageUpload />} />
          <Route path="postingspinner" element={<PostingSpinner />} />
          <Route path="dropzone" element={<Dropzone />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/newsfeed"
            element={
              <PrivateRoute>
                <Newsfeed />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </React.StrictMode>
  </ThemeSelector>
);
reportWebVitals();
