import React from "react";
import "./PostingSpinner.css";

const PostingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        zIndex: "1000",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        position: "absolute",
        width: "100%",
      }}
    >
      <div
        className="postingspinner"
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
          width: "200px",
          height: "35px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="posting-icon" style={{ zIndex: "100" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 0.625C9.30964 0.625 8.75 1.18464 8.75 1.875V5.625C8.75 6.31536 9.30964 6.875 10 6.875C10.6904 6.875 11.25 6.31536 11.25 5.625V1.875C11.25 1.18464 10.6904 0.625 10 0.625ZM16.6291 3.37088C16.141 2.88272 15.3495 2.88272 14.8614 3.37088L12.2097 6.02253C11.7216 6.51068 11.7216 7.30214 12.2097 7.79029C12.6979 8.27845 13.4893 8.27845 13.9775 7.79029L16.6291 5.13864C17.1173 4.65049 17.1173 3.85903 16.6291 3.37088ZM18.125 8.75C18.8154 8.75 19.375 9.30964 19.375 10C19.375 10.6904 18.8154 11.25 18.125 11.25H14.375C13.6846 11.25 13.125 10.6904 13.125 10C13.125 9.30964 13.6846 8.75 14.375 8.75H18.125ZM16.6291 16.6291C17.1173 16.141 17.1173 15.3495 16.6291 14.8614L13.9775 12.2097C13.4893 11.7216 12.6979 11.7216 12.2097 12.2097C11.7216 12.6979 11.7216 13.4893 12.2097 13.9775L14.8614 16.6291C15.3495 17.1173 16.141 17.1173 16.6291 16.6291ZM8.75 14.375C8.75 13.6846 9.30964 13.125 10 13.125C10.6904 13.125 11.25 13.6846 11.25 14.375V18.125C11.25 18.8154 10.6904 19.375 10 19.375C9.30964 19.375 8.75 18.8154 8.75 18.125V14.375ZM7.7903 12.2097C7.30214 11.7216 6.51069 11.7216 6.02253 12.2097L3.37088 14.8614C2.88272 15.3495 2.88272 16.141 3.37088 16.6291C3.85904 17.1173 4.65049 17.1173 5.13865 16.6291L7.7903 13.9775C8.27845 13.4893 8.27845 12.6979 7.7903 12.2097ZM5.625 8.75C6.31536 8.75 6.875 9.30964 6.875 10C6.875 10.6904 6.31536 11.25 5.625 11.25H1.875C1.18464 11.25 0.625 10.6904 0.625 10C0.625 9.30964 1.18464 8.75 1.875 8.75H5.625ZM7.7903 7.79028C8.27845 7.30212 8.27846 6.51067 7.7903 6.02251L5.13865 3.37086C4.65049 2.88271 3.85904 2.88271 3.37088 3.37086C2.88273 3.85902 2.88273 4.65047 3.37088 5.13863L6.02253 7.79028C6.51069 8.27843 7.30214 8.27843 7.7903 7.79028Z"
              fill="#7D7A7A"
            />
          </svg>
        </div>
        <div className="postinglabel">Posting....</div>
      </div>
    </div>
  );
};

export default PostingSpinner;
