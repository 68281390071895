import React, { useEffect, useState } from 'react';
import { supabase } from "../supabase";

const TvActivityNavbar = ({ currentPage }) => {

  const date = new Date();
  const [currentTime, setCurrentTime] = useState('00:00:00 AM');
  const [SchoolName, setSchoolName] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    window.schoolId = params.get("school");
    const mode = params.get("mode");
    if (SchoolName == '') {
      schoolName();
    }
    else {
      console.log("screenexit")
    };
    const timer = setTimeout(() => {
      const options = {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'Asia/Kolkata'
      };
      const formattedTime = new Date().toLocaleString('en-US', options);
      setCurrentTime(formattedTime);
    }, 1000);
  }, [currentTime]);

  async function schoolName() {
    let { data: School } = await supabase
      .from("School")
      .select("school_name")
      .eq("school_id", window.schoolId);
    window.schoolName = School[0].school_name;
    setSchoolName(School[0].school_name)
  }

  return (
    <div className='tv-navbar '>
      <div className='tv-school-name '>
        <h6>{SchoolName}</h6>
      </div>
      <div className='tv-housepoints'>
        ACTIVITY GALLERY
      </div>
      <div className='tv-time-date'>
        <div className='tv-time-logo'></div>
        <div className='tv-date-info'>{currentTime} | {date.toLocaleDateString()}</div>
      </div>
    </div>
  )
}

export default TvActivityNavbar