import React, { useEffect, useState } from "react";
import { supabase } from "../supabase";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [studentActId, setStudentAcId] = useState(null);

  //getting school and user id:
  const school_id = sessionStorage.getItem("school_id");
  const user_id = sessionStorage.getItem("user_id");

  //on drop:
  const onDropping = (event) => {
    event.preventDefault();
    const image = Array.from(event.dataTransfer.files).slice(
      0,
      5 - selectedImage.length
    );
    const imageUrl = image.map((file) => URL.createObjectURL(file));
    setSelectedImage([...selectedImage, ...imageUrl]);
  };

  //on drag:
  const onDragging = (event) => {
    event.preventDefault();
  };

  //on image change in upload:
  const handleImageChange = (event) => {
    const image = Array.from(event.target.files).slice(
      0,
      5 - selectedImage.length
    );
    const imageUrl = image.map((file) => URL.createObjectURL(file));
    setSelectedImage([...selectedImage, ...imageUrl]);
  };
  //handle cancel:
  const handleCancel = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    setSelectedImage(updatedImages);
  };

  //uploading image in ActivityMediaTable:
  const uploadImage = async () => {
    for (const image of selectedImage) {
      let { data: MemberId, errors } = await supabase
        .from("StudentActivity")
        .select("created_memberid")
        .eq("school_id", school_id);

      const createdMemberId = MemberId[0].created_memberid;

      let { data: HouseId, err } = await supabase
        .from("StudentActivity")
        .select("house_id")
        .eq("school_id", school_id);

      // Creating a row in student activity table:
      const { data, error } = await supabase
        .from("StudentActivity")
        .insert([
          {
            description: "all description",
            school_id: school_id,
            created_memberid: createdMemberId,
            house_id: 4,
          },
        ])
        .select();
      if (error) {
        console.log("error in adding data to student activity table");
      } else {
        if (data && data.length > 0) {
          const insertedRecord = data[0];
          window.studentActivityId = insertedRecord.studentactivity_id;
        }
      }

      // Getting the student activity id:
      try {
        const response = await fetch(image);
        const blob = await response.blob();
        const { data, error } = await supabase.storage
          .from("FeedImages")
          .upload(`image_${Date.now()}.png`, blob);
        if (error) {
          console.log("error uploadingg image", error);
        } else {
          console.log("image uploaded successfully", data);
        }

        const { dataS, errors } = await supabase
          .from("StudentActivityMedia")
          .insert([
            {
              media_link: data.path,
              studentactivity_id: window.studentActivityId, // Provide a valid non-null value here
              studentactivitymedia_id: 22,
            },
          ]);

        if (errors) {
          console.error("Error storing image URL:", errors);
        } else {
          console.log("Image URL stored successfully:", errors);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <h1 style={{ marginTop: "10px" }}>IMAGE UPLOADER</h1>

      {/* Drag and drop container */}
      <div
        onDrop={onDropping}
        onDragOver={onDragging}
        style={{
          border: "2px dashed gray",
          textAlign: "center",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
        className="draganddrop"
      >
        <p style={{ textAlign: "center" }}>Drag and drop the image here</p>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
      </div>
      {selectedImage.length > 0 && (
        <div style={{ marginTop: "1rem" }}>
          <p>Selected Images:</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {selectedImage.map((image, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img
                  src={image}
                  alt="Selected"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
                <button
                  onClick={() => {
                    handleCancel(index);
                  }}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <button style={{ border: "1px solid black" }} onClick={uploadImage}>
        Upload
      </button>

      <div
        style={{ backgroundColor: "#e3e3e3", width: "1800px", height: "500px" }}
        className="posts"
      >
        {}
      </div>
    </>
  );
};

export default ImageUpload;
