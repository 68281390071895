import React, { useState } from "react";
import { useNavigate } from "react-router";
import logo from "../assets/images/auth/logo.svg";
import { supabase } from "../supabase";
import expire from "../assets/images/auth/expired.svg";

function Expirepage() {
  let navigate = useNavigate();
  const MovetoSignin = () => {
    let path = `/signin`;
    navigate(path);
  };

  const Movetoforgotpassword = () => {
    let path = `/forgotpassword`;
    navigate(path);
  };
  return (
    <div className="login">
      <main className="login__main">
        {/* <!-- ====== Logo ====== --> */}
        <div className="login__brand forgot-password-brand">
          <div className="login__brand-logo">
            <img src={logo} alt="Logo" />
          </div>

          <h3 className="login__brand-title">House Point System</h3>
          <h5 className="login__brand-subtitle">Teacher Login</h5>
        </div>

        {/* <!-- ====== Content ====== --> */}
        <div className="login__main-content">
          <div className="login__card forgot-password-card">
            <div className="login__card-header"></div>

            <div className="card__heading expirelink">
              <img src={expire} alt="Mail icon" />
              <h5>Your reset password link has expired</h5>
              <p>reset link will expire in every 5 mins</p>
            </div>

            <form className="login__form">
              {/* <!-- Row 1 --> */}
              <div className="login__form-links">
                <button
                  type="submit"
                  className="btn btn-primary login__form-btn expirelink"
                  onClick={Movetoforgotpassword}
                >
                  Request new link
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>

      {/* <!-- ============= Background Images Start ========== --> */}
      <span className="login-top-img sm-none">
        <svg
          width="411"
          height="468"
          viewBox="0 0 411 468"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-192 364.5L-59.2411 231.741C5.20951 296.192 109.872 296.071 174.471 231.472C239.07 166.873 239.191 62.2106 174.74 -2.24004L307.499 -134.999C445.027 2.52968 444.77 226.08 306.925 363.926C169.079 501.771 -54.4715 502.029 -192 364.5Z"
            fill="currentColor"
          />
        </svg>
      </span>

      <span className="login-right-img">
        <svg
          width="262"
          height="525"
          viewBox="0 0 262 525"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M262 0V139.537C194.232 139.537 139.271 194.603 139.271 262.5C139.271 330.397 194.232 385.463 262 385.463V525C117.393 525 0 407.383 0 262.5C0 117.617 117.393 0 262 0Z"
            fill="currentColor"
          />
        </svg>
      </span>

      <span className="login-bottom-img">
        <svg
          width="481"
          height="199"
          viewBox="0 0 481 199"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M481 199L353.158 199C353.158 147.527 302.707 105.782 240.5 105.782C178.293 105.782 127.842 147.527 127.842 199L0 199C0 89.165 107.76 0 240.5 0C373.24 0 481 89.165 481 199Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {/* <!-- ============= Background Images End ============ --> */}

      {/* <!-- ============= Footer ============ --> */}
      <footer className="login__footer">
        <div className="login__footer-container">
          <div className="login__footer-row">
            <p>&copy; 2023 House Points System</p>
            <a href="#" className="login__footer-link">
              Contact Us
            </a>
          </div>

          <div className="login__footer-row">
            <a href="#" className="login__footer-link">
              Terms & Conditions
            </a>
            <a href="#" className="login__footer-link">
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Expirepage;
